import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { BlinkingDot } from "../../shared/ui/BlinkingDot";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import TimeAgo from 'react-timeago';


import { BORDER, COLORS, SPACING, TYPOGRAPHY } from "../../shared/constants";
import { Avatar, Icon,} from "../../shared/ui/Icon";
import { Card } from "../../shared/ui/Cards";
import { MeetingDate } from "./MeetingDate";
import { FlexContainer } from "../../shared/ui/Containers";
import { InputStyles, InputForm } from "../../shared/ui/Inputs";
import ActivityFeed from "../main/ActivityFeed";
import FeaturedFeed from "../main/FeaturedFeed";
import { getDashboardData } from "../../redux/actions/meetings";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import moment from "moment";

const MeetingItemContainer = styled(Card)`
  background-color: ${COLORS.azureRadiance};
  transition: all 0.25s ease-out;
  cursor: pointer;
  display: flex;
  background-color: ${({type, theme}) =>
    type === "live" ? theme.colors.active : theme.card.background};
  border: 1px solid;
  border-bottom: 5px solid;
  border-color: ${COLORS.transparent};
  margin-top: 0;
  margin-bottom: ${SPACING.SM}px;
  color: ${({theme, type}) => type === "live" ? COLORS.white : theme?.text?.color || COLORS.white};
  &:hover {
    border-color: ${({theme}) => theme.card.active};
  }
`;

const ScrollView = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
  padding-bottom: 20px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    padding-left: 4px;
    display: none;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.colors.grey1 || COLORS.greyDark};
    border-radius: 10px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
`;

const MeetingInfo = styled.div`
  width: calc(100% - 45px);
`;
const MeetingOptions = styled.div`
  width: 45px;
  padding-left: ${SPACING.XS}px;
  position: relative;
`;

const Section = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

const Search = styled.input`
  display: flex;
  margin-left: 30px;
  flex: 1;

  box-shadow: none;
  line-height: 0.8;
  padding: ${InputStyles.size.default};
  background: ${COLORS.transparent};
  border-radius: ${BORDER.radius_sm}px;
  border: 1px solid ${COLORS.white};
  color: ${COLORS.white};
  font-size: ${TYPOGRAPHY.standard}px;
`;

const MeetingItems = styled.div`
  // max-width: 800px;
`;

const MainView = styled.div`
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    padding-left: 4px;
    display: none;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.colors.grey1 || COLORS.greyDark};
    border-radius: 10px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
`

export const MeetingItem = ({ meeting, onMeetingSelected, id, type, isLoading, isTimeAgo }) => {
  const isValidDate = (dateStr) => {
    try{
      new Date(dateStr * 1000).toISOString();
      return true
    }catch(e){
      return false
    }
  }
  return (
    <MeetingItemContainer
      onClick={() => !isLoading && onMeetingSelected(id, meeting, type)}
      type={type}
    >
      <MeetingInfo>
        {isLoading ? (
          <>
          <Skeleton count={1} height="20px" width={"300px"} />
          <Skeleton count={1} height="20px" width={"300px"} />
          <br />
          <FlexContainer>
            <Skeleton circle count={1} height="30px" width={"30px"} style={{marginRight: SPACING.XXS}} />
            <Skeleton circle count={1} height="30px" width={"30px"} />
          </FlexContainer>
          </>
        ) : (
          <>
            <h4>{meeting?.topic || (type == "live" && meeting) }</h4>
            {meeting?.status !== "started" && !isTimeAgo? <MeetingDate
              light
              start_time={meeting?.start_time}
              end_time={meeting?.end_time}
            />: <span
                className="body-tiny"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                {meeting?.start_time && isValidDate(meeting?.start_time)  && <><TimeAgo style={{marginRight: 3}} date={   new Date(meeting?.start_time * 1000).toISOString()} /> | </>} 
               {(type !== "live") && <span style={{marginLeft: 3}}>{ moment.utc(meeting.duration * 1000).format("mm:ss")}</span>}
              </span>}
            {meeting?.participants && (
              <div style={{ marginTop: 14 }}>
                {Object.keys(meeting.participants).map((val, index) => (
                  <Avatar
                    key={val}
                    index={index}
                    name={meeting.participants[val].user_name}
                    round
                    size="30"
                    style={{
                      marginRight: SPACING.XXS,
                    }}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </MeetingInfo>
      <MeetingOptions>
        {meeting?.status === "started" || type == 'live' && (
          <div>
            <BlinkingDot />
            <span style={{ fontSize: "10px" }}>LIVE</span>
          </div>
        )}
        {meeting?.recordings && (
          <Icon
            type={"video"}
            style={{ position: "absolute", top: 0, right: 0 }}
          />
        )}
      </MeetingOptions>
    </MeetingItemContainer>
  );
};

const MeetingList = ({ onMeetingSelected, isLive }) => {
  const { meetings, live } = useSelector((state) => state.meetings);
  const liveMeetings = live || {};
  const [pastMeetings, setPastMeetings] = useState([]);
  const [filterPastMeetings, setFilterPastMeetings] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [teamFeedList, setTeamFeedList] = useState([]);
  const [featuredFeedList, setFeaturedFeedList] = useState([]);
  const [activityFeedList, setActivityFeedList] = useState([]);
  const {theme} = useAuth();


  let navigate = useNavigate();

  useEffect(() => {
    onSortMeetings();
  }, [meetings]);

  const handleDateChange = async (duration) => {
    getDashboardData(
      duration,
      setDashboardLoading,
      (teamFeed, featuredFeed) => {
        // setTeamFeedList(teamFeed);
        // setFeaturedFeedList(featuredFeed);
      },
      (error) => {}
    );
  };

  const onSortMeetings = () => {
    let liveMeetingArr = [];
    let pastMeetingArr = [];
    setLoading(true);

    Object.keys(meetings)
      .sort(
        (a, b) =>
          new Date(meetings[b]?.start_time) - new Date(meetings[a]?.start_time)
      )
      .map((key) => {
        if (meetings[key]?.status === "started") {
          liveMeetingArr.push(key);
        } else {
          pastMeetingArr.push(key);
        }
      });
    setPastMeetings(pastMeetingArr);
    setLoading(false);
    onFilterMeetings({ pastMeetingArr: pastMeetingArr });
  };

  const onFilterMeetings = ({
    searchValue = search,
    pastMeetingArr = pastMeetings,
  }) => {
    let pastMeetingsTemp = [...pastMeetingArr];
    const valueText = searchValue.toLowerCase();
    if (searchValue.length > 0) {
      pastMeetingsTemp = pastMeetingArr.filter((meetingId) =>
        meetings[meetingId]?.topic
          ? meetings[meetingId]?.topic
              .toLowerCase()
              .includes(valueText.toLowerCase())
          : false
      );
    }
    setFilterPastMeetings(pastMeetingsTemp);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    onFilterMeetings({ searchValue: value });
  };

  return (
    <SkeletonTheme
      baseColor={COLORS.lightBlue}
      highlightColor={COLORS.grey1}
      borderRadius="0.5rem"
      duration={0.5}
    >
      <MainView
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          width: "100%",
          position: "relative",
          height: "90%"
        }}
      >
        {isLive ? (
          <Section>
            <ScrollView>
              <div
                style={{
                  zIndex: 10,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  width: "100%",
                  height: "100%", 
                }}
              >
                 {Object.keys(liveMeetings).length > 0 && <> 
                    <h2 style={{ fontWeight: "bold" }}>Live Meetings</h2>
                    <MeetingItems>
                      {Object.keys(liveMeetings).length > 0 ? (
                        Object.keys(liveMeetings).map((key) => {
                          return (
                          <MeetingItem
                            type="live"
                            key={key}
                            id={key}
                            meeting={meetings[key] || liveMeetings[key]}
                            onMeetingSelected={onMeetingSelected}
                          />
                        )})
                      ) : (
                        <p style={{ margin: "0 8px", color: theme.colors.grey1 }}>
                          (No Live Meetings)
                        </p>
                      )}
                    </MeetingItems>
                  </>}
                <FeaturedFeed
                  loading={dashboardLoading}
                  featuredFeedList={[...featuredFeedList,...teamFeedList]}
                />
                <ActivityFeed
                  loading={dashboardLoading}
                  activityFeedList={activityFeedList}
                />
              </div>
            </ScrollView>
          </Section>
        ) : (
          <Section
            style={{ flexGrow: 1, paddingBottom: "50px", }}
          >
            <FlexContainer
              alignItems={"center"}
              justifyContent="space-between"
              style={{ marginBottom: SPACING.SM }}
            >
              <FlexContainer alignItems={"center"}>
                <span style={{ width: 40, cursor: "pointer" }}>
                  <Icon
                    onClick={() => {
                      navigate("/");
                    }}
                    type={"caretLeft"}
                    style={{ fontSize: 40 }}
                  />
                </span>
                <h2 style={{ fontWeight: "bold", marginBottom: 0 }}>History</h2>
              </FlexContainer>
              <InputForm
                value={search}
                onChange={handleChange}
                placeholder={"Search meetings"}
                style={{ marginRight: 16, marginBottom: 0 }}
              />
            </FlexContainer>
            <PerfectScrollbar
              component="div"
              style={{
                width: "100%",
                height: "calc(85vh - 150px)"
              }}
            >
              {loading ? (
                <>
                  <MeetingItem isLoading />
                  <MeetingItem isLoading />
                  <MeetingItem isLoading />
                </>
              ) : (
                <MeetingItems>
                  {filterPastMeetings?.length > 0 ? (
                    filterPastMeetings
                    .filter(key => meetings[key]?.start_time)
                    .map((key) => (
                      <MeetingItem
                        key={key}
                        id={key}
                        meeting={meetings[key]}
                        onMeetingSelected={onMeetingSelected}
                      />
                    ))
                  ) : (
                    <p style={{ margin: "0 8px", color: theme.text.grey}}>
                      (No Past Meetings)
                    </p>
                  )}
                </MeetingItems>
              )}
            </PerfectScrollbar>
          </Section>
        )}
      </MainView>
    </SkeletonTheme>
  );
};

export default MeetingList;
