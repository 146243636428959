import { TinyText } from "shared/ui/Headers";
import { SPACING } from "shared/constants";
import styled from "styled-components";
import { Textarea } from "shared/ui/Inputs";
import { Button } from "shared/ui/Buttons";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  getComments,
  removeComment,
  updateComment,
} from "../../redux/actions/meetings";
import { useEffect } from "react";
import moment from "moment";
import { Avatar, Icon, ICON_TYPES } from "../../shared/ui/Icon";
import { ModalBody, Modal, PopOver, PopOverItem } from "../../shared/ui/Modals";
import { BORDER } from "shared/constants";
import { useRef } from "react";
const COMMENT_TEXT_LIMIT = 30000;
const Scroll = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Container = styled.div`
  background-color: ${({ theme }) =>
    theme?.colors?.secondaryBackground || "transparent"};
  padding: 30px;
  height: 100%;
  border-left: 1px solid ${({ theme }) => theme?.border?.color || BORDER.color};
  display: flex;
  flex-direction: column;
`;

const CommentContainer = styled.div`
  ${({ theme, isLast, isNote }) => `
    background-color: transparent;
    border-bottom: 1px solid ${theme.border.color || BORDER.color};
    color: ${theme?.text?.color};
    ${isLast ? `margin-bottom: ${SPACING.MD}px` : ``};
    padding: ${SPACING.SM}px 0 0;
    ${
      isNote
        ? `max-height: 250px;
         margin-bottom: ${SPACING.SM}px;`
        : ``
    }
  `}
`;

const CommentHeader = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${SPACING.XS}px;
  justify-content: space-between;
  width: 100%;
`;

const Actions = ({ id, onEdit, onDelete, isNote }) => {
  return (
    <div style={{ marginLeft: SPACING.XS }}>
      <Icon
        id={"comment_actions_" + id}
        type={ICON_TYPES.ellipsis}
        style={{ cursor: "pointer" }}
      />
      <PopOver
        placement="bottom"
        target={"comment_actions_" + id}
        trigger="legacy"
      >
        <PopOverItem onClick={onEdit}>Edit</PopOverItem>
        {!isNote && (
          <PopOverItem danger onClick={onDelete}>
            Delete
          </PopOverItem>
        )}
      </PopOver>
    </div>
  );
};

const Comment = ({
  comment,
  user,
  onDeleteComment,
  onSubmit,
  isOwner,
  isNote,
}) => {
  const name = user?.firstName + " " + user?.lastName;
  const [isEdit, setIsEdit] = useState();
  const [updateComment, setUpdateComment] = useState(comment.body);
  const [isLoading, setLoading] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);
  const handleChange = (e) => {
    const { value } = e.target;
    if (!(value.length > COMMENT_TEXT_LIMIT)) {
      setUpdateComment(value);
    }
  };
  const onSetEdit = () => {
    setIsEdit(!isEdit);
  };

  const onEdit = async () => {
    if (comment?.id) {
      setLoading(true);
      await onSubmit({ id: comment?.id, body: updateComment });
      setLoading(false);
      onSetEdit();
    }
  };

  const onDelete = async () => {
    await onDeleteComment(comment?.id);
  };

  useEffect(() => {
    setUpdateComment(comment.body);
  }, [comment]);

  let commentBody = comment.body;
  let isSeeMore = false;
  if (isNote && commentBody?.length > 400) {
    commentBody = commentBody?.slice(0, 400) + "...  ";
    isSeeMore = true;
  }
  return (
    <CommentContainer isNote={isNote && !isShowMore}>
      <CommentHeader>
        <div style={{ flexGrow: 1, display: "flex" }}>
          <Avatar name={name} size="30" round />
          <div style={{ marginLeft: SPACING.XS }}>
            <p
              style={{
                fontWeight: "bold",
                marginBottom: 0,
              }}
            >
              {name}
            </p>
            {isNote && <TinyText> Meeting note</TinyText>}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <TinyText>
            {moment(new Date(comment.dateCreated)).format("M/D/yyyy")}
          </TinyText>
          {isOwner && (
            <Actions
              onEdit={onSetEdit}
              onDelete={onDelete}
              isNote={isNote}
              id={comment?.id}
            />
          )}
        </div>
      </CommentHeader>

      {isEdit ? (
        <>
          <Textarea
            style={{ minHeight: 120 }}
            value={updateComment}
            onChange={handleChange}
            maxlength={COMMENT_TEXT_LIMIT}
          />
          {updateComment.length >= COMMENT_TEXT_LIMIT && (
            <TinyText>Text limit reached</TinyText>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: SPACING.XS,
              paddingBottom: SPACING.SM,
            }}
          >
            <Button
              style={{ marginRight: SPACING.XS }}
              size="sm"
              isLoading={isLoading}
              onClick={onEdit}
            >
              Update
            </Button>
            <Button
              size="sm"
              onClick={onSetEdit}
              color="light"
              disabled={isLoading}
            >
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <>
          <p>
            {isSeeMore && isShowMore ? comment.body : commentBody}
            {isSeeMore && (
              <span
                style={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => setIsShowMore(!isShowMore)}
              >
                {isShowMore ? " Hide" : "Show more"}
              </span>
            )}
          </p>
        </>
      )}
    </CommentContainer>
  );
};

const MeetingComments = ({
  isOwner,
  interactionId,
  notes,
  onNoteChange,
  accessCode,
  noteOwnerId,
  dateCreated,
  isLive,
  children,
}) => {
  const { user_id, companyId } = useSelector((state) => state.member?.claims);
  const users = useSelector((state) => state.users?.users);
  const [loading, setLoading] = useState(false);
  const [isCommentSend, setIsCommentSend] = useState(false);
  const commentRef = useRef();
  const [text, setText] = useState("");
  const [comments, setComments] = useState([]);

  const handleChange = (e) => {
    const { value } = e.target;
    setText(value);
  };

  const onSubmitComment = async (comment = false) => {
    setIsCommentSend(true);
    try {
      await updateComment({
        companyId,
        uid: user_id,
        comment: comment?.id ? comment?.body : text,
        interactionId,
        commentId: comment?.id,
      });
      onGetComments();
    } catch (e) {
      console.log(e, " update err");
    }
  };

  const onDeleteComment = async (id) => {
    await removeComment({
      companyId,
      commentId: id,
    });
    onGetComments();
  };

  const onGetComments = async () => {
    setLoading(true);
    const commentsTemp = await getComments({ companyId, interactionId });
    setComments(commentsTemp || []);
    setText("");
    setLoading(false);
    setIsCommentSend(false);
  };

  useEffect(() => {
    onGetComments();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [comments]);

  const scrollToBottom = () => {
    if (commentRef?.current) {
      commentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container>
      {isLive ? (
        <div style={{ flex: "1 1 auto" }}>
          <h2 style={{ fontWeight: "bold", marginBottom: SPACING.SM }}>
            Notes
          </h2>
          <Textarea
            onChange={(e) => onNoteChange(e.target.value)}
            value={notes}
            style={{ minHeight: "90%", padding: 0, border: 0, background: "transparent" }}
            placeholder="Type to add note"
          />
        </div>
      ) : (
        <>
          <div style={{ flex: "1 1 auto" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: SPACING.LG,
                height: "100%",
              }}
            >
              <h2 style={{ fontWeight: "bold", marginBottom: SPACING.SM }}>
                Comments
              </h2>
              {loading && <p>Loading...</p>}
              <>
                {!loading && notes && (
                  <Comment
                    id={"note"}
                    comment={{ dateCreated, body: notes, id: "note" }}
                    isOwner={isOwner}
                    user={users?.[noteOwnerId]}
                    onUpdate={onNoteChange}
                    onSubmit={({ body }) => onNoteChange(body)}
                    onDeleteComment={onDeleteComment}
                    isNote
                  />
                )}
                <Scroll>
                  {comments?.length
                    ? comments.map((comment, i) => {
                        return (
                          <Comment
                            key={i}
                            id={comment?.id}
                            comment={comment}
                            isOwner={user_id === comment?.ownerId}
                            user={users?.[comment.ownerId]}
                            onSubmit={onSubmitComment}
                            onDeleteComment={onDeleteComment}
                          />
                        );
                      })
                    : null}
                  <div style={{ padding: 50 }}></div>
                  <div ref={commentRef} />
                </Scroll>
              </>
            </div>
          </div>
          <div style={{ paddingTop: SPACING.SM }}>
            <Textarea
              placeholder="Add a comment"
              onChange={handleChange}
              value={text || ""}
            />
            <Button
              onClick={() => onSubmitComment()}
              disabled={text?.length === 0 || loading || isCommentSend}
              isLoading={isCommentSend}
              style={{ display: "block", margin: "14px 0 0 auto" }}
            >
              Comment
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};

export default MeetingComments;
