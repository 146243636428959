import axios from "axios";
import { BASE_URL, ENV, RUNNING_ENV, REPORT_SERVICE_BASE_URL, MEETINGS_SERVICE_BASE_URL } from "../../shared/constants";
import { realtimeDb } from "../../firebase/firebase";
import { isDevRunningEnvironment } from "../../shared/utils/common";
import { RECORD_TYPES } from "../../shared/constants/recordTypes";
import { getUserInfo } from "./users";
import moment from "moment";

let availableMeetingListener = null;
let meetingListRef = null;

let upcomingMeetingListener = null;
let upcomingMeetingRef = null;

let calendarListener = null;
let calendarListenerRef = null;

let scheduledMeetingListener = null;
let scheduledMeetingRef = null;

let liveMeetingListener = null;
let liveMeetingListenerRef = null;


let userLiveMeetingListenerRef = null;
let userLiveMeetingListener = null;

export const listenToLiveUserMeetings = (companyId, uid) => {
  return dispatch => {
    userLiveMeetingListenerRef = realtimeDb.ref(`Users/${companyId}/${uid}/liveMeetings`);
      try{
        userLiveMeetingListener = userLiveMeetingListenerRef
        .on("value", (snapshot) => {
          let meeting = snapshot.val();
          dispatch({ type: "UPDATE_LIVE_USER_MEETINGS", data: meeting });

        });
      }catch(e){
        console.error(e);
      }
  }
}

export const unsubscribeLiveUserMeetingListener = () => {
  return dispatch => {
    if(userLiveMeetingListenerRef && userLiveMeetingListener) {
      userLiveMeetingListenerRef.off("value", userLiveMeetingListener);
      dispatch({ type: "UPDATE_LIVE_USER_MEETINGS", data: {} });
    }
  }
}

export const listenToLiveMeeting = (companyId, interactionId) => {
  return dispatch => {
    console.log(`Interactions/${companyId}/meetings/${interactionId}`);
    liveMeetingListenerRef = realtimeDb.ref(`Interactions/${companyId}/meetings/${interactionId}/realtimeTranscript`);
      try{
        liveMeetingListener = liveMeetingListenerRef
        .on("value", (snapshot) => {
          let meeting = snapshot.val();
          dispatch({ type: "LIVE_MEETING_INTERACTION", data: meeting });

        });
      }catch(e){
        console.error(e);
      }
  }
}

export const unsubscribeLiveMeetingListener = () => {
  return dispatch => {
    if(liveMeetingListener && liveMeetingListenerRef) {
      liveMeetingListenerRef.off("value", liveMeetingListener);
      dispatch({ type: "LIVE_MEETING_INTERACTION", data: {} });
    }
  }
}

export const getAvailableMeetings = async () => {
  const dev = isDevRunningEnvironment();
  const response = await axios.post(`${BASE_URL}/zoom/available-meetings`, {
    dev,
  });
  return response.data;
};

export const validateMeeting = async (companyId, interactionId) => {
  return new Promise((resolve, reject) => {
    realtimeDb
      .ref(`Interactions/${companyId}/meetings/${interactionId}`)
      .once("value")
      .then((snapshot) => {
        resolve(snapshot.val());
      });
    // onValue(
    //   ref(realtimeDb, `Interactions/${companyId}/meetings/${interactionId}`),
    //   (snapshot) => {
    //     resolve(snapshot.val());
    //   }
    // );
  });
};

export const updateMeetingLabel = async (
  companyId,
  interactionId,
  topic,
  dev,
  test,
  uid,
  meetingUuid,
  meetingTopic,
  recordingTopic
) => {
  const singledEncodedUuid = await encodeURIComponent(meetingUuid);
  const doubleEncodedUuid = await encodeURIComponent(singledEncodedUuid);
  if (!companyId || !interactionId || !topic) return;
  if (recordingTopic && doubleEncodedUuid) {
    try {
      await axios.post(`${BASE_URL}/process-request`, {
        url: `https://api.zoom.us/v2/meetings/${doubleEncodedUuid}/recordings/settings`,
        method: "PATCH",
        dev: dev,
        test: test,
        companyId: companyId,
        uid: uid,
        data: {
          topic: topic,
        },
      });
    } catch (error) {
      console.log("Error updating recording topic", error);
    }
  }

  if (meetingTopic && doubleEncodedUuid) {
    try {
      await axios.post(`${BASE_URL}/process-request`, {
        url: `https://api.zoom.us/v2/meetings/${doubleEncodedUuid}`,
        method: "PATCH",
        dev: dev,
        test: test,
        companyId: companyId,
        uid: uid,
        data: {
          topic: topic,
        },
      });
    } catch (error) {
      console.log(
        "Unable to update meeting topic, likely no meeting only a recording. Check if there's a meeting. Error:",
        error
      );
    }
  }
  try {
    await realtimeDb
      .ref(`Interactions/${companyId}/meetings/${interactionId}`)
      .update({ topic: topic });
  } catch (error) {
    console.log("Error updating Upmarket with new meeting label");
  }
};

export const updateInteraction = async (companyId, interactionId, interaction) => {
  if(!interactionId || !companyId) return
  await realtimeDb
    .ref(`Interactions/${companyId}/meetings/${interactionId}`)
    .update({ ...interaction});
}

export const updateMeetingTimeline = async (
  companyId,
  interactionId,
  timeline,

) => {
  await realtimeDb
    .ref(`Interactions/${companyId}/meetings/${interactionId}`)
    .update({ timeline: timeline});
};

export const getDashboardData = (
  duration,
  setDashboardLoading,
  onSuccess,
  onFailure,
  limit
) => {
  setDashboardLoading(true);
  const userWithMostMeetingsPromise = axios.post(
    `${REPORT_SERVICE_BASE_URL}/interaction/stats/feed_team_most_interactions`,
    { ...duration, type: "meetings",limit }
  );

  const userWithMostSuccessfulMeetingsPromise = axios.post(
    `${REPORT_SERVICE_BASE_URL}/interaction/stats/feed_team_successful_user`,
    { ...duration, type: "meetings",limit }
  );

  const userContentMostSuccessfulPromise = axios.post(
    `${REPORT_SERVICE_BASE_URL}/interaction/stats/feed_team_successful_content`,
    { ...duration, type: "meetings",limit }
  );

  const breakout = axios.post(
    `${REPORT_SERVICE_BASE_URL}/interaction/stats/feed_team_breakout`,
    {
      ...duration,
      type: "meetings",
      limit
    }
  );

  const risingUserPromise = axios.post(
    `${REPORT_SERVICE_BASE_URL}/interaction/stats/feed_team_rising`,
    { ...duration, type: "meetings" ,limit}
  );

  //============================================

  //featured list
  const notSuccessfulPromise = axios.post(
    `${REPORT_SERVICE_BASE_URL}/interaction/stats/feature_not_successful`,
    {
      ...duration,
      type: "meetings",
      limit
    }
  );

  const featureRisingPost = axios.post(
    `${REPORT_SERVICE_BASE_URL}/interaction/stats/feed_feature_rising`,
    {
      ...duration,
      type: "meetings",
      limit
    }
  );

  const contentComboPromise = axios.post(
    `${REPORT_SERVICE_BASE_URL}/interaction/stats/feed_feature_content_combo`,
    { ...duration, type: "meetings" , limit}
  );

  Promise.allSettled([
    userWithMostMeetingsPromise,
    userWithMostSuccessfulMeetingsPromise,
    userContentMostSuccessfulPromise,
    breakout,
    risingUserPromise,
    //Below ae the activity Feed promises
    notSuccessfulPromise,
    contentComboPromise,
    featureRisingPost,
  ])
    .then(async (response) => {
      const teamFeed = response
        .slice(0, 5)
        .filter(
          (element) =>
            element &&
            element.status === "fulfilled" &&
            element.value &&
            element.value.data &&
            element.value.data.id
        )
        .map((res) => res?.value.data);
      const featuredFeed =  response
        .slice(5, 8)
        .filter(
          (element) =>
            element &&
            element.status === "fulfilled" &&
            element.value &&
            element.value.data &&
            element.value.data.id
        )
        .map((res) => res?.value.data);
      await onSuccess(teamFeed, featuredFeed);
    })
    .catch((error) => {
      console.log('ERROR', error)
      onFailure(error);
    })
    .finally(() => {
      setTimeout(() => {
        setDashboardLoading(false);
      }, 2000);
    });
};

export const updateMeeting = async (companyId, interactionId, meeting) => {
  console.log()
  return realtimeDb
    .ref(`Interactions/${companyId}/meetings/${interactionId}`)
    .update({ ...meeting });
};

export const updateMeetingPrepare = async (
  companyId,
  uid,
  interaction,
  timeline,
  id = false
) => {
  const lastModified = new Date().toISOString();
  if (id) {
    await realtimeDb
      .ref(`Users/${companyId}/${uid}/zoom/saved/${id}`)
      .update({ ...interaction, timeline, lastModified });
  } else {
    await realtimeDb.ref(`Users/${companyId}/${uid}/zoom/saved`).push({
      ...interaction,
      timeline,
      dateCreated: lastModified,
      lastModified,
    });
  }
};

export const deleteMeetingPrepare = async (companyId, uid, id) => {
  if (id) {
    await realtimeDb.ref(`Users/${companyId}/${uid}/zoom/saved/${id}`).remove();
  }
};

export const searchSalesforce = async (searchTerm, uid, companyId, type) => {
  try {
    let searchParams = `sobject=Account&sobject=Contact&sobject=Lead&sobject=Opportunity&Opportunity.fields=id,name&Account.fields=id,name&Contact.fields=id,name&Lead.fields=id,name`;
    if (type) {
      searchParams = `sobject=${type}&${type}.fields=id,name`;
    }
    const response = await axios.get(
      "https://us-central1-accountheroes.cloudfunctions.net/sfdc-request-get",
      {
        params: {
          uid: uid,
          companyId: companyId,
          request: `parameterizedSearch/?q=${searchTerm}&${searchParams}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error, "error");
  }
};

export const getMeetingLink = async (interactionId) => {
  try {
    const response = await axios.post(`${BASE_URL}/zoom/get-share-link`, {
      interactionId,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const updateSalesforceTask = async (
  uid,
  companyId,
  interactionId,
  task,
  taskId
) => {
  try {
    const taskResponse = await axios.get(
      "https://us-central1-accountheroes.cloudfunctions.net/sfdc-request-patch",
      {
        params: {
          uid: uid,
          companyId: companyId,
          request: `sobjects/Task/${taskId}`,
          data: task,
        },
      }
    ).catch(e => console.log(e, 'UPDATE ERROR'));
    console.log("Response Data", taskResponse.data);
    return taskResponse.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export const createSalesforceTask = async (
  uid,
  companyId,
  interactionId,
  task,
) => {
  console.log('create task', task)
  try {
    const taskResponse = await axios.get(
      "https://us-central1-accountheroes.cloudfunctions.net/sfdc-request-post",
      {
        params: {
          uid: uid,
          companyId: companyId,
          request: "sobjects/Task",
          data: task,
        },
      }
    );
    console.log("Response Data", taskResponse.data);
    return taskResponse.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getMeetingWins = (
  companyId,
  outcomeId,
  start = false,
  end = false
) => {
  return new Promise((resolve, reject) => {
    if (start && end) {
    } else {
      if(!outcomeId) return resolve({});
      return realtimeDb
        .ref(`Interactions/${companyId}/meetings`)
        .orderByChild("dispositionId")
        .equalTo(outcomeId)
        .once("value", (snapshot) => {
          resolve(snapshot.val() || {});
        });
    }
  });
};

export const getUserMeetings = (uid, companyId) => {
  console.log(uid, companyId, 'GET USER MEETINGS')
  return (dispatch) => {
      if(!uid || !companyId) return 
      meetingListRef = realtimeDb.ref(`Interactions/${companyId}/meetings`);
      try{
        availableMeetingListener = meetingListRef
        .orderByChild("uid")
        .equalTo(uid)
        .once("value")
        .then( (snapshot) => {
          let meetings = snapshot.val();
          if (meetings) {
            const meetingList = [];
            const sortedMeetings = {};
            Object.keys(meetings).map((key) =>
              meetingList.push({ key: key, ...meetings[key] })
            );
            meetingList.sort(
              (a, b) => new Date(b.start_time) - new Date(a.start_time)
            );
            meetingList.map((val) => {
              const { key, ...rest } = val;
              sortedMeetings[val.key] = rest;
            });
            meetings = sortedMeetings;
          }
          dispatch({ type: "UPDATE_USER_MEETINGS", data: meetings });
        })
        .catch(e => console.log(e));

      }catch(e){
      }
 

      // const dbRef = ref(realtimeDb, `Interactions/${companyId}/meetings`);
      // const queryConstraints = [orderByChild("uid"), equalTo(uid)];
      // get(query(dbRef, ...queryConstraints)).then((snapshot) => {
      //   dispatch({type: "UPDATE_USER_MEETINGS", data: snapshot.val()})
      // });
    // });
  };
};

export const getUserCalendars = (uid, companyId) => {
  if(!uid || !companyId) return
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      calendarListenerRef = realtimeDb.ref(`CalendarEvents/${companyId}/${uid}/Calendars`);
      try{
        calendarListener = calendarListenerRef
        .on("value", (snapshot) => {
          let calendars = snapshot.val();
          dispatch({ type: "UPDATE_USER_CALENDARS", data: calendars });
          resolve();
        });
      }catch(e){
        reject(e)
      }
    });
  };
}

export const getUserScheduledMeetings = (uid, companyId, limit = 30) => {
  return (dispatch) => {
      upcomingMeetingRef = realtimeDb.ref(`CalendarEvents/${companyId}/${uid}/Events`);
      const startTime = moment().utc().unix() 
      // const endTime =  moment(new Date(startTime * 1000)).add(2, 'M').utc().unix() ;
      try{
        upcomingMeetingListener = upcomingMeetingRef
        .orderByChild("start_time")
        .startAfter(startTime)
        .limitToFirst(limit)
        .on("value", (snapshot) => {
          let meetings = snapshot.val();
          dispatch({ type: "UPDATE_USER_SCHEDULED_MEETINGS", data: meetings });
        })
        ;
      }catch(e){
        console.log(e,'error')
      }
  };
};

export const getUserScheduledMeetingsLimit = (uid, companyId, limit = 30) => {
  return (dispatch) => {
      upcomingMeetingRef = realtimeDb.ref(`CalendarEvents/${companyId}/${uid}/Events`);
      const startTime = moment().utc().unix() 
      try{
        upcomingMeetingListener = upcomingMeetingRef
        .orderByChild("start_time")
        .startAfter(startTime)
        .limitToFirst(limit)
        .once("value").then(snapshot => {
          let meetings = snapshot.val();
          dispatch({ type: "UPDATE_USER_SCHEDULED_MEETINGS", data: meetings });
        })
        ;
      }catch(e){
        console.log(e,'error')
      }
  };
};

export const getUserScheduledBots = (uid) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if(!uid) return resolve();
      scheduledMeetingRef = realtimeDb.ref(`Mapping/Meetings/Bots`);

      try{
        scheduledMeetingListener = scheduledMeetingRef
        .orderByChild("uid")
        .equalTo(uid)
        .on("value", (snapshot) => {
          let meetings = snapshot.val();
          dispatch({ type: "UPDATE_USER_SCHEDULED_BOTS", data: meetings });
          resolve();
        });
      }catch(e){
        reject(e)
      }
    });
  };
};

export const addMeetingBot = async(uid, companyId, eventId) => {
  try {
    await axios.post(`${MEETINGS_SERVICE_BASE_URL}/nylas/add-meeting-bot`, {uid, companyId, eventId})
  } catch (error) {
    if(error?.response?.data) {
      throw error.response.data;
    }
    console.error(error);
  }
}

export const removeMeetingBot = async(uid, companyId, eventId) => {
  try {
    await axios.post(`${MEETINGS_SERVICE_BASE_URL}/nylas/remove-meeting-bot`, {uid, companyId, eventId})
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const createMeetingBot = async (uid, companyId, meetingUrl, botName) => {
  try {
    await axios.post(`${MEETINGS_SERVICE_BASE_URL}/recall/create`,
      {
        uid: uid,
        companyId: companyId,
        meetingUrl: meetingUrl,
        botName: botName
      }
    ).then((response) => {
      console.log("completed create request", response)
      return response?.data;
    });
  } catch (error){  
    if(error?.response?.data) {
      throw error.response.data;
    }
    console.log("error creating bot", error);
  }
};

export const getInteraction = ({companyId, interactionId, accessCode, isAdmin, uid}) => {
  return new Promise(async (resolve, reject) => {
    const dev = isDevRunningEnvironment();
    let test = RUNNING_ENV === ENV.TEST;
    axios
      .post(
        `${BASE_URL}/zoom/interactions/${interactionId}`,
        {
          // dev,
          // test,
          companyId, interactionId, accessCode, isAdmin, uid
        },
        {
          headers: { "Access-Code": accessCode },
        }
      )
      .then((response) => {
        resolve(response?.data);
      });
  });
};

export const updateParticipantRecords = async (
  companyId,
  interactionId,
  participantId,
  records
) => {
  const { contactId, contactName, accountId, accountName, isContactLinked } =
    records;
  await realtimeDb
    .ref(
      `Interactions/${companyId}/meetings/${interactionId}/participants/${participantId}`
    )
    .update({
      isContactLinked,
      contactId: contactId ? contactId : false,
      contactName: contactName ? contactName : false,
      accountId: accountId ? accountId : false,
      accountName: accountName ? accountName : false,
    });

  // await update(
  //   ref(realtimeDb, `Interactions/${companyId}/meetings/${interactionId}/participants/${participantId}`),
  //   { isContactLinked, contactId: contactId ? contactId : false, contactName: contactName ? contactName : false, accountId: accountId ? accountId: false, accountName: accountName? accountName: false  }
  // );
};

export const deleteContactGrids = async (
  companyId,
  gridId,
  activityId,
  taskId
) => {
  await realtimeDb
    .ref(
      `Grids/${companyId}/${gridId}/activities/${activityId}/Tasks/${taskId}`
    )
    .remove();
  // await remove(
  //   ref(realtimeDb, `Grids/${companyId}/${gridId}/activities/${activityId}/Tasks/${taskId}`));
};

// export const downloadMedia = async(companyId, uid, interactionId) => {
//   const dev = isDevRunningEnvironment();
//   await axios.get(`${BASE_URL}/zoom/${companyId}/${uid}/${interactionId}/media?dev=${dev}`)
// };

// export const getVideoUrl = async(companyId, uid, interactionId, fileName) => {
//   const response = await axios.get(`${BASE_URL}/zoom/download-url/${companyId}/${uid}/${interactionId}/${fileName}`);
//   return response?.data?.length ? response.data[0]: null;
// }

export const updateInteractionSync = async (
  companyId,
  interactionId,
  taskId,
  recordId,
  type,
  isUploadTranscript,
  note,
  isUpdated
) => {
  const syncData = {
    // ...(type === RECORD_TYPES.CONTACT && { contactId: recordId }),
    // ...(type === RECORD_TYPES.LEAD && { leadId: recordId }),
    // ...(type === RECORD_TYPES.ACCOUNT && { accountId: recordId }),
    // ...(type === RECORD_TYPES.OPPORTUNITY && { opportunityId: recordId }),
    ...(type === RECORD_TYPES.CONTACT && { type, recordId }),
    taskId,
    isUploadTranscript,
  };

  await realtimeDb
    .ref(`Interactions/${companyId}/meetings/${interactionId}/sync/salesforce`)
    .update(syncData);
    if(!isUpdated && isUploadTranscript){
      await axios.post(`${BASE_URL}/zoom/create-task/salesforce`, {
        companyId,
        interactionId,
        note,
        isUpdated
      });
    }
};

export const removeInteractionSync = async (companyId, interactionId) => {
  await realtimeDb
    .ref(`Interactions/${companyId}/meetings/${interactionId}/sync/salesforce`)
    .remove();
};

export const shareMeetingEmail = async ({interactionId, email, name, userEmail, meetingLabel}) => {
  // const response = await axios.post(`${BASE_URL}/zoom/share-meeting`, {
  //   interactionId,
  //   email,
  //   name,
  //   meetingLabel,
  //   userEmail
  // });
  // return response;
  const { data } = await axios.post(`${BASE_URL}/zoom/get-share-link`, {
    interactionId
  })

  if(data?.shareUrl){
    await axios.post(`https://email-scheduler-dot-accountheroes.appspot.com/sendSharedMeetingLink`, {
      interactionId,
      email,
      name,
      meeting_label: meetingLabel,
      userEmail,
      play_url: data.shareUrl
    })
  }
};

export const getPlayDetails = async (accessCode) => {
  const response = await axios.post(
    `${BASE_URL}/zoom/validate-access-code`,
    { accessCode },
    { headers: { accessCode } }
  );
  
  return response?.data;
};

export const deleteMeetingListeners = () => {

  if(upcomingMeetingRef && upcomingMeetingListener) {
    upcomingMeetingRef.off("value", upcomingMeetingListener);
  }
};

export const deleteCalendarListeners = () => {
  console.log("deleteCalendarListeners")
  if (calendarListener && calendarListenerRef) {
    console.log("calendarListenerRef")
    calendarListenerRef.off("value", calendarListener);
  }

  if(scheduledMeetingRef && scheduledMeetingListener) {
    console.log("scheduledMeetingListener")
    scheduledMeetingRef.off("value", scheduledMeetingListener);
  }
}

export const getSfdc = async (request, companyId, uid) => {
  
  const response = await axios.get(
    "https://us-central1-accountheroes.cloudfunctions.net/sfdc-request-get",
    {
      params: {
        uid: uid,
        companyId: companyId,
        request: request,
      },
    }
  );

  return response.data;
};

export const delSfdc = async (request, companyId, uid) => {
  const response = await axios.get(
    "https://us-central1-accountheroes.cloudfunctions.net/sfdc-request-delete",
    {
      params: {
        uid: uid,
        companyId: companyId,
        request: request,
      },
    }
  );

  return response.data;
};

export const patchSfdc = async (request, data, companyId, uid) => {
  const response = await axios.get(
    "https://us-central1-accountheroes.cloudfunctions.net/sfdc-request-patch",
    {
      params: {
        uid: uid,
        companyId: companyId,
        request: request,
        data: data,
      },
    }
  );
  return response.data;
};

export const postSfdc = async (request, data, companyId, uid) => {
  const response = await axios.get(
    "https://us-central1-accountheroes.cloudfunctions.net/sfdc-request-post",
    {
      params: {
        uid: uid,
        companyId: companyId,
        request: request,
        data: data,
      },
    }
  );
  return response.data;
};

export const updateRecordFieldOrder = (companyId, uid, type, fieldOrder) => {
  return (dispatch) => {
    realtimeDb
      .ref(`Users/${companyId}/${uid}/layouts/zoom/salesforce`)
      .update({ [type]: fieldOrder });
  };
};

export const updateComment = async ({uid, companyId, comment, interactionId, commentId}) => {
  if(!uid || !companyId || !interactionId) return
  if(commentId){
    await realtimeDb.ref(`Records/${companyId}/Note/${commentId}`).update({body: comment, lastModified: new Date().toISOString()})
  }else{
    let noteData = {
      parentId: interactionId,
      parentType: 'Note',
      dateCreated: new Date().toISOString(),
      ownerId: uid,
      body: comment,
      type: 'comment'
    }
    await realtimeDb.ref(`Records/${companyId}/Note`).push(noteData).then(res => {
      let pushId = res.key;
      realtimeDb.ref(`Interactions/${companyId}/meetings/${interactionId}/Note`).update({
        [pushId] : noteData.dateCreated
      })
    })
  }
}

export const removeComment = async ({ companyId, commentId}) => {
  if( !companyId || !commentId) return
  if(commentId){
    try{
      await realtimeDb.ref(`Records/${companyId}/Note/${commentId}`).remove()
    }catch(e){
      console.log(e, 'error')
    }
  }
}

export const getComments = async({companyId, interactionId}) => {
  if(!companyId || !interactionId) return []
  return await realtimeDb.ref(`Records/${companyId}`)
    .child('Note')
    .orderByChild('parentId')
    .equalTo(interactionId)
    .once("value")
    .then(snapshot => {
      const commentObj = snapshot.val() || {}
      return Object.keys(commentObj).map(id => ({...commentObj[id], id}))
    });
}

