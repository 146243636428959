export const initialState = {
    meetings: {},
  };
  
  export default function memberReducer(state = initialState, action) {
    switch (action.type) {
      case "LIVE_MEETING_INTERACTION": {
        return {
          ...state,
          realtimeTranscript: action.data,
        };
      }

      case "UPDATE_LIVE_USER_MEETINGS": {
        return {
          ...state,
          live: action.data
        }
      }

      case "UPDATE_USER_MEETINGS": {
        return {
          ...state,
          meetings: {
            ...action.data,
          },
        };
      }

      case "UPDATE_USER_SCHEDULED_MEETINGS": {
        return {
          ...state,
          scheduledMeetings: {
            ...action.data,
          },
        };
      }
      case "UPDATE_USER_CALENDARS": {
        return {
          ...state,
          calendars: {
            ...action.data,
          },
        };
      }


      case "UPDATE_USER_SCHEDULED_BOTS": {
        return {
          ...state,
          scheduledBots: {
            ...action.data,
          },
        };
      }

      case "UPDATE_MEETING_INTETACTIONS": {
        return {
          ...state,
          meetings: {
           ...state.meetings,
           [action.data.id]:{
              ...state.meetings[action.data.id],
              ...action.data.value
           }
          },
        }
      }
  
      default:
        return state;
    }
  }
  