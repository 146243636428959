import { useSelector } from "react-redux";
import { CONTENT_TYPE, SPACING, TYPE_LIST } from "../../shared/constants";
import { Button } from "../../shared/ui/Buttons";
import { useAuth } from "../../contexts/AuthContext";

const MeetingPlanList = ({
  list,
  callFlowId,
  meetingPlans,
  handleCallReasonSelect,
  handleTemplate,
  isPrep,
}) => {
  const { company } = useSelector((state) => state.content);
  const { saved } = useSelector((state) => state.users?.info?.zoom || {});
  const { theme } = useAuth(); 

  return (
    <>
      {list?.length ? list.map((key, index) => (
        <Button
          key={key}
          tag="button"
          onClick={() => handleCallReasonSelect(key, true)}
          style={{
            width: "100%",
            margin: `0 auto ${SPACING.SM}px `,
            padding: `${SPACING.MD}px ${SPACING.MD}px`,
            maxWidth: 800,
            backgroundColor: theme.card.active,
            color: "#fff"
          }}
        >
          <div className="p"> {company.Triggers?.[key]?.label} </div>
        </Button>
      )) : <p style={{textAlign: 'center', fontWeight: 600, padding: 25}}> No {CONTENT_TYPE[TYPE_LIST.TRIGGERS].controlLabelPlural} found. Try a different {CONTENT_TYPE[TYPE_LIST.CALLFLOWS].controlLabel} </p>}
      {!isPrep && callFlowId && (
        <>
          {saved && <hr />}
          {saved &&
            Object.keys(saved)
              .filter((key) => saved[key].callFlowId === callFlowId)
              .map((key, index) => (
                <Button
                  key={key}
                  tag="button"
                  onClick={() => handleTemplate(saved[key])}
                  style={{
                    width: "100%",
                    margin: `0 auto ${SPACING.SM}px `,
                    padding: `${SPACING.MD}px ${SPACING.MD}px`,
                    maxWidth: 800,
                    backgroundColor: theme.card.active,
                  }}
                >
                  <div className="p"> {saved[key]?.topic} </div>
                </Button>
              ))}
        </>
      )}
    </>
  );
};

export default MeetingPlanList;
