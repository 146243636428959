import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import styled, { ThemeProvider } from "styled-components";
import { toast } from "react-toastify";

import { getUserInfo, updateUserZoomAuth } from "../../redux/actions/users";
import { useAuth } from "../../contexts/AuthContext";
import { collectAppAccessData } from "../../redux/actions/connections";
import Header from "../main/Header";
import { APP_LAYOUT, APP_THEME, COLORS } from "../../shared/constants";
import { BodyContainer, FlexContainer } from "../../shared/ui/Containers";
import { Loader } from "shared/ui";
import background from "assets/background.png";

export const Background = styled.div`
  overflow: hidden;
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  ${({ theme }) =>
    theme?.type !== APP_LAYOUT.ZOOM
      ? `
        background-color: ${theme?.colors?.backgroundColor};
        color: ${theme?.text?.color};
        a{
          color: ${theme?.text?.color};
        }
      `
      : `
      background-image: url(${background});
      background-size: cover;
      background-color: ${COLORS.softBlack};
    `}
`;

export const BackgroundContainer = ({ theme, children }) => {
  return (
    <Background theme={theme}>
      <ThemeProvider theme={theme}> {children} </ThemeProvider>
    </Background>
  );
};

const AuthWrapper = ({ children, showHeader }) => {
  const { appName } = useParams();
  const { socialProfile, signInWithCustomToken, theme } = useAuth();
  const { info, errors } = useSelector((state) => state.users);
  const { pageLoader } = useSelector((state) => state.loaders);
  const { layout , isHeader} = useSelector((state) => state.content);
  const dispatch = useDispatch();
  const { uid, companyId } = info || {};

  const navigate = useNavigate();

  async function getAppData(code, appName, isInvitedMember) {
    const { id: userId } = await collectAppAccessData(code, appName);
    if (userId) {
      localStorage.setItem("appUserId", userId);
    }

    if (isInvitedMember && uid && companyId && userId) {
      // await updateUserZoomAuth(userId, true);
      dispatch(getUserInfo(uid, companyId));
      // toast("Added Zoom Successfully", {
      //   hideProgressBar: true,
      // });
      localStorage.removeItem("inviteState");
    }
  }

  useEffect(() => {
    const url = new URL(document.location);
    let code = url.searchParams.get("code");
    let state = url.searchParams.get("state");
    let layoutParam = url.searchParams.get("layout");
    if (!layoutParam && code && appName) {
      getAppData(code, appName || "zoom", state === "invite");
    }

  }, []);

  useEffect(() => {
    if (errors && Object.keys(errors)?.length) {
      navigate("/home");
      dispatch({ type: "USER_TOKEN_ERROR", data: null });
    }
  }, [errors]);

  useEffect(() => {
    if (socialProfile?.uid) {
      navigate("/register/new/" + socialProfile.uid);
    }
  }, [socialProfile]);
  return (
    <BackgroundContainer theme={theme || APP_THEME[APP_LAYOUT.UPMARKET]}>
      {uid && showHeader && isHeader &&  (<Header />)}
      {pageLoader ? (
        <FlexContainer
          justifyContent="center"
          alignItems="center"
          style={{ height: "80%" }}
        >
          <Loader />
        </FlexContainer>
      ) : (
        <BodyContainer style={{ zIndex: 1, flex: 1 }}>
          {React.cloneElement(children, {
            logoutCallback: () => localStorage.removeItem("appUserId"),
          })}
        </BodyContainer>
      )}
    </BackgroundContainer>
  );
};

AuthWrapper.defaultProps = {
  showHeader: true,
};

export default AuthWrapper;
