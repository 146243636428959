import axios from "axios";
import { auth, functions, realtimeDb } from "../../firebase/firebase";
import { BASE_URL } from "../../shared/constants";
import { RECORD_TYPES } from "../../shared/constants/recordTypes";

export const inviteTeamMembers = async (team) => {
  return axios.post(`${BASE_URL}/zoom/invite-team`, { team })
}

export const isSalesforceConnected = async (uid, companyId) => {
  return realtimeDb.ref(`Connections/${companyId}/${uid}/auth/salesforce`).once("value").then((snapshot) => {
    console.log(snapshot.val(), "SFDC")
  
    return snapshot.val() || false
  })
}
export const getUserSalesforceData = (uid, companyId) => {
  console.log('USER INGOI')
  return (dispatch) => {
    axios.get('https://us-central1-accountheroes.cloudfunctions.net/sfdc-process-request-1', {
      params: {
        uid: uid,
        companyId: companyId,
        request: `https://login.salesforce.com/services/oauth2/userinfo`,
        serviceCall: "true"
      }
    }).then(async response => {
      if (response?.data) {
        const requestKey = Object.keys(response.data)[0];
        const salesforceUser = response.data[requestKey];
        const version = await realtimeDb.ref(`Companies/Global/salesforce/version`).once("value").then((snapshot) => {
          return snapshot.val() || false
        }).catch((err) => console.log(err, 'SALESFORCE VERSION'))
        dispatch({
          type: "UPDATE_USER_SALESFORCE_INFO",
          data: {...salesforceUser, version},
        });
      }
    });
  }
}

export const getSalesforceRecordData = (uid, companyId) => {
  return (dispatch) => {
    const requests = Object.keys(RECORD_TYPES).map(type => axios.get('https://us-central1-accountheroes.cloudfunctions.net/sfdc-request-get', {
      params: {
        uid: uid,
        companyId: companyId,
        request: `sobjects/${RECORD_TYPES[type]}/describe`,
      }
    }))

    Promise.allSettled(requests).then(data => {
      if (data && data?.length) {
        let fields = {};
        data.filter(val => val.status === "fulfilled").map(val => {
          if (val?.value?.data) {
            const response = val.value.data;
            const requestUrl = Object.keys(response)[0];
            const type = requestUrl.split("/")[1];
            const dataResponse = response[requestUrl].fields;
            fields[type] = dataResponse;//?.length ? dataResponse.map(({nillable, updateable, name, label}) => ({nillable, updateable, name, label})): [];
          }
        });

        dispatch({
          type: "UPDATE_USER_SALESFORCE_RECORD_INFO",
          data: fields,
        });
      }

    })
  }
}

export async function findUserCompany(email) {
  const handleCompanyId = functions.httpsCallable("findUserCompany");
  return new Promise(async (resolve, reject) => {
    try {
      const result = await handleCompanyId({ email });
      resolve(result?.data || {})
    } catch (e) {
      reject({ error: e })
    }
  })
}

export function resetPassword(email, companyId) {
  let url = `https://app.upmarket.ai/${companyId}/login`
  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "0.0.0.0") {
    url = `https://localhost:3001/${companyId}/login`
  }
  let actionCodeSettings = {
    url: url,
    handleCodeInApp: true
  };

  return new Promise(async (resolve, reject) => {
    await auth.sendSignInLinkToEmail(email, actionCodeSettings)
      .then(function (success) {
        console.log("the email login worked", success)
        resolve(true)
      })
      .catch(function (error) {
        console.log("the email login didn't work", error)
        reject(error)
      })
  })
}