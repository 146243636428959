import React from "react";
import PropTypes from "prop-types";
import { COLORS, COLOR_ARR, TYPE_LIST } from "../constants";
import styled, { keyframes } from "styled-components";
import AvatarRA from "react-avatar";

const IconMap = {
  clock: "far fa-clock",
  caretDown: "fas fa-caret-down caret-icon",
  caretLeft: "fal fa-angle-left caret-icon",
  caretRight: "fal fa-angle-right caret-icon",
  caretUp: "fas fa-caret-up caret-icon",
  caretDoubleRight: "fad fa-angle-double-right",
  chevronDown: "far fa-chevron-down",
  chevronUp: "far fa-chevron-up",
  close: "far fa-times",
  delete: "fal fa-times-circle delete-icon ",
  dropdownDown: "fal fa-angle-down dropdown-icon",
  dropdownUp: "fal fa-angle-up dropdown-icon",
  edit: "fad fa-edit",
  edit_l: "fal fa-edit",
  exchange: "far fa-exchange-alt",
  trashcan: "fad fa-trash-alt",
  ellipsis: "fas fa-ellipsis-v ellipsis-icon",
  save: "far fa-save",
  forward: "fad fa-forward",
  script: "fad fa-scroll-old",
  pencil: "fas fa-pencil-alt",
  video: "fa-solid fa-video",

  email: "fad fa-envelope-open  email-icon",
  info: "fal fa-info-circle info-icon",
  emailOutline: "far fa-envelope",
  phoneOutline: "far fa-phone",
  phone: "fad fa-phone-volume  phone-icon",
  dialerPhone: "fas fa-phone",
  bringFront: "far fa-bring-front",
  plus: "fas fa-plus plus-icon",
  plusBox: "far fa-plus-square plus-icon",
  plusCircle: "far fa-plus-circle ",
  userCheck: "fas fa-user-check",
  userPlus: "fas fa-user-plus",
  userCaution: "fas fa-exclamation-triangle",
  unLink: "fas fa-unlink",
  trophy: "far fa-trophy",

  minus: "far fa-minus",
  check: "fad fa-check-circle success",
  user: "fad fa-user user-icon",
  user_l: "fal fa-user user-icon",
  user_s: "fas fa-user user-icon",
  user_add: "fal fa-user-plus user-add-icon",
  warning: "far fa-exclamation-circle",
  star: "far fa-star",
  filledStar: "fa-solid fa-star",
  square: "fal fa-square",
  square_checked: "fal fa-check-square",
  flag_checkered: "fal fa-flag-checkered",
  fire: "fa-solid fa-fire-flame",
  bulleye: "fas fa-bullseye-arrow",
  question: "fad fa-question",
  trendUp: "fa-solid fa-arrow-trend-up",
  rocket: "fa-solid fa-rocket",
  gavel: "fad fa-gavel",
  note: "far fa-sticky-note",
  search: "far fa-search search-icon",
  clipboard: "fas fa-clipboard",
  sync: "far fa-sync",
  loader: "fad fa-spinner-third loader-icon",
  filter: "fas fa-filter filter-icon",
  ellipsis_h: "fas fa-ellipsis-h ellipsis-h-icon",
  power: "far fa-power-off",
  bookmark: "far fa-bookmark",
  crown: "fa-solid fa-crown",
  balloons: "fa-regular fa-balloon",
  sparkles: "fa-solid fa-sparkles",
  exclamation: "fa-solid fa-octagon-exclamation",
  hide: "fa-regular fa-eye-slash",
  unhide: "fa-regular fa-eye",
  //Grid
  grid: "fas fa-th",
  grid_lg: "far fa-th-large",
  list: "fas fa-list",
  copy: "fas fa-copy",
  // linkedIn: "fab fa-linkedin-in",
  linkedIn: "fa fa-linkedin-square",
  calendar: "far fa-calendar-alt",

  // Telephony
  backspace: "far fa-backspace",
  play: "fas fa-play",
  pause: "fas fa-pause",
  unmute: "far fa-microphone-alt",
  mute: "far fa-microphone-alt-slash",
  setting: "fas fa-cog ",
  headphones: "fad fa-headphones-alt",

  //Menu
  hospital: "fas fa-hospital-user icon",
  building: "fad fa-building icon",
  headset: "fas fa-headset icon",
  phoneBook: "fas fa-address-book",
  signOut: "fas fa-sign-out-alt",
  userProfile: "far fa-user",
  users: "fas fa-users",
  users_l: "fal fa-users",
  users_two_s: "fas fa-user-friends",
  crown: "fas fa-crown",
  content: "fas fa-pen-square",
  analytics: "fas fa-chart-pie",
  tasks: "fas fa-tasks",
  library: "fa-duotone fa-square-kanban",
  location: "fa-regular fa-location-dot",
  //Email
  send: "fas fa-paper-plane",
  recent: "fas fa-history",
  file: "far fa-file",
  inbox: "fas fa-inbox",
  outbox: "far fa-paper-plane",
  sentArrow: "fas fa-share",
  recievedArrow: "fas fa-reply",
  pen: "fas fa-pen",
  eye: "fad fa-eye",
  eyeCancelled: "fad fa-eye-slash",
  share: "fas fa-share-alt",
  transcript: "fa-sharp fa-solid fa-closed-captioning",

  //Content
  [TYPE_LIST.OBJECTIONS]: "far fa-exclamation-circle",
  [TYPE_LIST.DISCOVERY_QUESTIONS]: "far fa-comments",
  [TYPE_LIST.SOCIALPROOFS]: "far fa-asterisk",
  [TYPE_LIST.TRIGGERS]: "far fa-bullseye-pointer",
  [TYPE_LIST.BATTLESCARDS]: "far fa-chess-rook",
  [TYPE_LIST.FAQS]: "far fa-question-circle",
  [TYPE_LIST.EMAILS]: "far fa-envelope",
  [TYPE_LIST.DISPOSITIONS]: "far fa-check-double",
  [TYPE_LIST.VOICEMAILS]: "far fa-voicemail",
  [TYPE_LIST.STATS]: "fad fa-flask",
  playbook: "far fa-book-spells"
};

const Container = styled.i`
  cursor:  ${props => props.onClick ? 'pointer' : 'default'};
  &:hover{
    ${({ onClick, theme, id }) => (onClick || id) && `color: ${theme?.colors?.primary} || ${COLORS.primary}`}
  }
`
export const ICON_TYPES = Object.keys(IconMap).reduce((iconTypes, key) => {
  iconTypes[key] = key;
  return iconTypes;
}, {});

export const Icon = (props) => {
  let { type, className, style, onClick, id } = props;

  return (
    <Container
      id={id}
      onClick={onClick}
      className={
        "icon " + IconMap[type?.replace(/ /g, "")] + " " + (className || "")
      }
      style={style || null}
    ></Container>
  );
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
};

const Spin =  keyframes`{
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}`


const Loader = styled(Icon)`
  animation: ${Spin} 1s linear infinite;
  --fa-primary-color: #{${({theme}) => theme.colors.primary || COLORS.primary}};
  --fa-secondary-color: transparent;
  margin: 0
`
export const LoaderIcon = () => {
  return <Loader type="loader" />
}


export const randomNumber = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  let index = Math.floor(Math.random() * (max - min + 1)) + min;
  return index
}

export const getAvatarColor = (name) => {
  const firstChar = name ? name.toLowerCase().charAt(0).charCodeAt(0) - 97 : 0;
  // const color = COLOR_ARR[Math.floor(Math.random() * 27)]//Randomly pick one of the 26 colors defined 
  // const color = `#${Math.random().toString(16).substr(-6)}`//Totally random
  const color = COLOR_ARR[firstChar]// pick based on first index alphabet

  return color
}
export const getListItemColor = (index) => {
  return  COLOR_ARR[index]
}


export const Avatar = (props) => {
  const {index, avatarColor}=props
  // const firstChar = props.name ? props.name.toLowerCase().charAt(0).charCodeAt(0) - 97 : 0;
  const color = avatarColor ? avatarColor: COLOR_ARR[index];
  return <AvatarRA {...props} color={color} />
}

Avatar.defaultProps={
  index:0
}