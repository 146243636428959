import moment from "moment";
import { COLORS, SPACING } from "../../shared/constants";
import { Icon, ICON_TYPES } from "../../shared/ui/Icon";
import { Button } from "../../shared/ui/Buttons";
import { useAuth } from "../../contexts/AuthContext";

export const MeetingDate = ({ start_time, end_time, live , light}) => {
  const {theme} = useAuth()
  if (!start_time) return <></>
  let duration = 0
  const date = moment.unix(start_time).format("MM/DD/YYYY hh:mm A");
  const startTime = moment.unix(start_time);
  if (end_time) {
    const endTime = moment.unix(end_time);
    duration = moment.duration(endTime.diff(startTime));
    duration = moment.utc(duration.asSeconds() * 1000).format("HH:mm:ss");
  }

  return (
    <>
      <span
        className="body-tiny"
        style={{
          display: "flex",
          alignItems: "center",
          color: light ? theme.text.white : theme.text.grey,
          marginBottom: 0,
        }}
      >
        <Icon type={ICON_TYPES.clock} style={{ marginRight: SPACING.XXS }} />
        {date} {duration ? `| ${duration} mins` : ""}
        {live && <Button style={{ backgroundColor: COLORS.eastBay, height: 25, width: 50, marginLeft: 10, cursor:"auto" , color: "#fff"}}>Live</Button>}
      </span>
    </>
  );
};
