import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form, Tooltip } from "reactstrap";
import { InputForm } from "../../shared/ui/Inputs";

import styled from "styled-components";
import {
  APP_LAYOUT,
  BORDER,
  COLORS,
  CONTENT_TYPE,
  SPACING,
  TYPE_LIST,
  TYPOGRAPHY,
} from "../../shared/constants";
import { Button } from "../../shared/ui/Buttons";
import { ControlledPopOver, Modal, ModalBody } from "../../shared/ui/Modals";

import {
  FlexContainer,
  GridContainer,
  ScrollContainer,
} from "../../shared/ui/Containers";
import { Icon, ICON_TYPES } from "../../shared/ui/Icon";
import { PopOver } from "../../shared/ui/Modals";
import { ControlledTooltip, ToolTipItem } from "../../shared/ui/ToolTips";
import { useKeyPress } from "../../shared/utils/keyPress";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useDispatch, useSelector } from "react-redux";

const CALL_CONTENT_OPTIONS = [
  {
    type: TYPE_LIST.DISCOVERY_QUESTIONS,
    label: CONTENT_TYPE[TYPE_LIST.DISCOVERY_QUESTIONS].controlLabel,
  },
  {
    type: TYPE_LIST.OBJECTIONS,
    label: CONTENT_TYPE[TYPE_LIST.OBJECTIONS].controlLabel,
  },
  {
    type: TYPE_LIST.SOCIALPROOFS,
    label: CONTENT_TYPE[TYPE_LIST.SOCIALPROOFS].controlLabel,
  },
  {
    type: TYPE_LIST.BATTLESCARDS,
    label: CONTENT_TYPE[TYPE_LIST.BATTLESCARDS].controlLabel,
  },
  {
    type: TYPE_LIST.FAQS,
    label: CONTENT_TYPE[TYPE_LIST.FAQS].controlLabel,
  },
  {
    type: ICON_TYPES.note,
    label: "Notes",
  },
  {
    type: ICON_TYPES.transcript,
    label: "Transcript",
  },
];

const ListItemContainer = styled.div`
  font-size: ${TYPOGRAPHY.standard}px;
  padding: ${SPACING.XS}px;
  padding-left: ${SPACING.SM}px;
  position: relative;
  width: 100%;
  border-radius: ${BORDER.radius_xs}px;
  &:hover,
  &.active {
    background: ${({ theme, isSuggestion }) => isSuggestion ? theme?.colors.secondary : theme?.colors?.active || COLORS.active};
    color: #fff;
  }
`;

const ListContainer = styled.div`
  overflow-y: auto;
  width: 100%;
  max-height: 200px;
  padding-right: 20px;
  padding-top: 8px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    padding-left: 4px;
    display: none;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      theme.type == APP_LAYOUT.ZOOM
        ? COLORS.quickSilver
        : theme?.colors?.grey1 || COLORS.grey1};
    border-radius: 10px;
    cursor: pointer;
    margin-right: 2px;
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
`;

const Container = styled.div`
  ${(props) =>
    !props.hideActions ? `padding: ${SPACING.XS}px ${SPACING.SM}px;` : ""}
  border-radius: ${BORDER.radius_lg}px;
  ${(props) =>
    !props.hideActions ? `border: 1px solid ${props.theme.border.color};` : ""}
  background-color: ${({ theme }) =>
    theme.type == APP_LAYOUT.ZOOM ? "#1f1c28" : "#f3f5f9"};
  min-width: 100%;

  @media screen and (min-width: 450px) {
    min-width: 380px;
  }

  max-height: 400px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const OptionButton = styled(Button)`
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-color: transparent;
  border-radius: ${BORDER.radius_sm}px;
  padding: 0;

  &#note_option{
    @media screen and (min-width: 768px){
      display: none
    }
  }
  i {
    color: rgb(85, 85, 85);
    font-size: 14px;
  }
  &:hover,
  &.active,
  &:focus {
    border-color: transparent;
    background-color: #aac4ff;
    i {
      color: #fff;
      font-size: 14px;
    }
  }
`;

const SearchInput = styled(InputForm)`
  background-color: ${({ theme }) =>
    theme.type == APP_LAYOUT.ZOOM ? "#1f1c28" : "#fff"};
  color: ${({ theme }) => theme.text.color};
  margin-bottom: ${SPACING.SM}px;
`;

const AnalyticsBtn = styled.div`
  padding: ${SPACING.SM}px;
  border-radius: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  background-color: ${({ theme }) => theme?.colors.secondary || COLORS.purple};
  width: 60px;
  height: 60px;
  cursor: pointer;
  border: 8px solid
    ${({ theme }) =>
      theme.type == APP_LAYOUT.ZOOM
        ? "#191821"
        : theme?.colors?.backgroundColor || "#191821"};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover,
  &.active {
    background: ${({ theme }) => theme?.colors?.dark || COLORS.azureRadiance};
  }
  i {
    cursor: pointer;
  }
`;

const ListItem = ({ index, handleSelect, active, val, label }) => {
  const itemRef = useRef();
  return (
    <ListItemContainer
      ref={itemRef}
      className={active ? "active" : ""}
      onMouseDown={handleSelect}
      style={{ cursor: "pointer" }}
      key={index}
      ariaSelected={active}
      active={active}
      isSuggestion={val.isSuggestion}
      tabIndex={active ? (index > 4 ? -1 : 0) : index}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          itemRef.target.blur();
        }
      }}
    >
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        {val?.type && <Icon type={val?.type} style={{ marginRight: 8 }} />}{" "}
        {label || val?.label}
      </div>
    </ListItemContainer>
  );
};

export const NotesModal = ({
  isOpen,
  toggle,
  notes,
  isLive,
  onSave,
  disabled,
}) => {
  const [notesUpdate, setNotesUpdate] = useState(notes);
  const handleChange = (e) => {
    const { value } = e.target;
    setNotesUpdate(value);
  };

  useEffect(() => {
    setNotesUpdate(notes);
    return () => {
      setNotesUpdate("");
    };
  }, [notes]);

  return (
    <Modal title={"Notes"} isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <InputForm
          disabled={disabled}
          type={"textarea"}
          value={notesUpdate}
          placeholder={disabled ? "No notes" : "Add note here..."}
          onChange={handleChange}
          style={{ minHeight: 200 }}
        />
        {!disabled && (
          <FlexContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={() => {
                onSave(notesUpdate);
                toggle();
              }}
            >
              Save
            </Button>
          </FlexContainer>
        )}
      </ModalBody>
    </Modal>
  );
};

const MeetingOptionPanel = ({
  onOptionSelect,
  type,
  isLive,
  showToolTip = true,
  options,
  isPrep,
  handleContenOptionSelect,
  allOptions = [],
  onNoteChange,
  notes = "",
  hideActions,
  suggessions = [],
  selectedContentOption,
}) => {
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const [isSearch, setIsSearch] = useState(true);
  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const [isNoteAdded, setIsNoteAdded] = useState(false);
  const [isEnter, setIsEnter] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(allOptions);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [showToolbar, setShowToolbar] = useState(true);
  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  const enterPressed = useKeyPress("Enter");
  let { accessCode } = useParams();
  const isSuggestionOn = useSelector((state) => state?.users?.isSuggestionOn);
  const onSetSuggestion = () => {
    dispatch({
      type: "USER_SET_SUGGESTION",
      data: !isSuggestionOn,
    });
  };
  const handleChange = (e) => {
    const { value } = e.target;
    if (!isEnter) {
      setText(value);
      if (value.length > 0) {
        onOptionSelect();
        setSelectedIndex(0);
        const filteredArr = allOptions ? allOptions.filter((opt) =>
          opt?.label?.toLowerCase().includes(value.toLowerCase())
        ): [];
        setFilteredOptions(filteredArr);

        if (value.charAt(0) === "/") {
          setIsSearch(false);
          setShowSuggestion(false);
        }else{
          setIsSearch(true);
        }
        if(filteredArr.length){
          setShowSuggestion(true);
        }
      } else if (!isSearch) {
        setIsSearch(true);
        setShowSuggestion(false);
      } else if (value.length === 0) {
        setFilteredOptions(allOptions || []);
        setSelectedIndex(0);

        setShowSuggestion(false);
      } else {
        setShowSuggestion(false);
      }
    } else {
      setIsEnter(false);
      setShowSuggestion(false);
    }
  };

  const onNoteAdd = useCallback(
    (e) => {
      if (!isSearch && e.key === "Enter" && e.shiftKey === false) {
        let notesTemp = notes
          .slice(1)
          .concat((notes.length > 0 ? "\r\n\r\n" : "") + text.slice(1));
        onNoteChange(notesTemp);
        setIsNoteAdded(true);
        setText("");
        setIsSearch(true);
        setTimeout(() => {
          setIsNoteAdded(false);
        }, 1200);
        setIsEnter(true);
      } else {
        if (e.key === "Enter" && e.shiftKey === false) {
          setIsEnter(true);
          onKeyPressChange("select", selectedIndex);
        }
      }
    },
    [isSearch, text, notes, onNoteChange, selectedIndex]
  );

  const onKeyPressChange = (key, i) => {
    console.log('here')
    switch (key) {
      case "arrowUp":
        setSelectedIndex(
          selectedIndex !== 0 ? selectedIndex - 1 : filteredOptions.length - 1
        );
        return;
      case "arrowDown":
        setSelectedIndex(
          selectedIndex !== filteredOptions.length - 1 ? selectedIndex + 1 : 0
        );
        return;
      case "select":
        if(filteredOptions[i]){
          handleContenOptionSelect({
            id: filteredOptions[i]?.id,
            type: filteredOptions[i]?.type,
          });
          handleChange({ target: { value: "" } });
          setShowSuggestion(false);
        }
        return;
      default:
        throw new Error();
    }
  };

  useEffect(() => {
    if (arrowUpPressed) {
      onKeyPressChange("arrowUp");
    }
  }, [arrowUpPressed]);

  useEffect(() => {
    if (arrowDownPressed) {
      onKeyPressChange("arrowDown");
    }
  }, [arrowDownPressed]);

  useEffect(() => {
    if (enterPressed && !isSearch) {
      onKeyPressChange("select", selectedIndex);
      setShowSuggestion(false);
    }
  }, [enterPressed]);

  useEffect(() => {
    if (isLive && isSuggestionOn && suggessions?.length > 0) {
      setShowSuggestion(true);
      const filterSug = filteredOptions.filter(opt => !opt.isSuggestion)
      setFilteredOptions([...(suggessions.length > 3 ? suggessions.splice(0,3) : suggessions), ...filterSug]);
    }else{
      setFilteredOptions(allOptions)
    } 
  }, [suggessions]);

  useEffect(() => {
    if (isLive && selectedContentOption && !showSuggestion) {
      let tempOptions = allOptions.filter(
        (opt) => selectedContentOption == opt.type
      );
      if(tempOptions.length > 0){
        tempOptions[0] = {
          ...tempOptions[0],
          popupTitle: CONTENT_TYPE?.[selectedContentOption]?.controlLabelPlural || ""
        }

      }
      setFilteredOptions(tempOptions);
      setShowSuggestion(true);
    } else {
      setFilteredOptions(allOptions);
      if (text.length === 0) {
        setShowSuggestion(false);
      }
    }
  }, [selectedContentOption, allOptions]);

  return (
    <Container id="meeting_container" hideActions={hideActions}>
      <ControlledTooltip
        target="meeting_container"
        isOpen={isNoteAdded}
        placement="top"
      >
        Note Added
      </ControlledTooltip>
      <NotesModal
        isOpen={isNoteOpen}
        toggle={() => setIsNoteOpen(!isNoteOpen)}
        notes={notes}
        onChange={onNoteChange}
        isLive={isLive}
        onSave={onNoteChange}
        disabled={accessCode}
      />

      <>
        {isLive && (
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              position: 'relative'
            }}
          >
            { showSuggestion && filteredOptions.length && (!selectedContentOption || selectedContentOption != TYPE_LIST.transcript) ? (
              <>
               <div
                  style={{position: 'absolute',  right: 0, top: 8}}
                >
                  <Icon
                    type={ICON_TYPES.close}
                    style={{fontSize: TYPOGRAPHY.h3}}
                    onClick={() => {
                      setShowSuggestion(false);
                    }}
                  />
                </div>
                <ListContainer>
                  {(
                    <>
                      {/* {isSearch && <h5 style={{margin: '8px 0 '}}>{ "Search" }</h5>} */}
                      {filteredOptions.map((val, index) => (<div>
                      {val.popupTitle && <h5 style={{margin: '8px 0 '}}>{ val.popupTitle }</h5>}
                        <ListItem
                          handleSelect={() => {
                            handleContenOptionSelect({
                              id: val?.id,
                              type: val?.type,
                            });
                            handleChange({ target: { value: "" } });
                            setShowSuggestion(false);
                          }}
                          key={index}
                          role="button"
                          active={index === selectedIndex}
                          val={val}
                          index={index}
                        />
                         </div>
                      ))}

                    </>
                  )}
                </ListContainer>
              </>
            ): null}

            <form
              style={{ width: "100%", marginTop: SPACING.XS }}
              id="meeting_search"
            >
              <SearchInput
                id="search_field"
                // type="textarea"
                style={{
                  maxHeight: 100,
                  // minHeight: showToolbar ? 50 : 0,
                  marginBottom: SPACING.SM,
                }}
                value={text}
                onChange={handleChange}
                placeholder={"Search Content or type / for a note"}
                onKeyPress={onNoteAdd}
              />
            </form>
          </div>
        )}
        <FlexContainer
          style={{
            flexDirection: "column",
          }}
        >
          {type !== TYPE_LIST.SENTENCE &&
            type !== TYPE_LIST.STATS &&
            !hideActions && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: !isLive ? 10 : 0,
                  }}
                >
                  {CALL_CONTENT_OPTIONS.filter((option) => {
                    return isPrep ? 
                           option.type !== 'transcript' && option.type !== TYPE_LIST.STATS : 
                            isLive ? 
                           option.type !== TYPE_LIST.STATS : true  ;
                  }).map((option, i) => (
                    <div key={i} style={{ height: "100%" }}>
                      <OptionButton
                        style={{ height: "100%"}}
                        className={
                          selectedContentOption === option.type ? "active" : ""
                        }
                        id={option.type + "_option"}
                        onClick={() => {
                          setShowSuggestion(false);
                          if (option.type === "note") {
                            setIsNoteOpen(true);
                            onOptionSelect(false);
                          } else {
                            onOptionSelect(option.type);
                            setIsSearch(false)
                          }
                        }}
                      >
                        <Icon
                          type={ ICON_TYPES[option.type]
                          }
                          style={{ fontSize: 18}}
                        />
                      </OptionButton>
                    </div>
                  ))}
                </div>
                <div style={{ height: "100%" }}>
                  <OptionButton
                    style={{ marginLeft: 10 }}
                    className={isSuggestionOn ? "active" : ""}
                    onClick={onSetSuggestion}
                    size="sm"
                  >
                    <Icon
                      className={
                        "fa-regular fa-lightbulb" +
                        (!isSuggestionOn ? "-slash" : "")
                      }
                      style={{ fontSize: TYPOGRAPHY.h3 }}
                    />
                  </OptionButton>
                </div>
              </div>
            )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              width: "100%",
            }}
          >
            {!isLive && (
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    height: "50px",
                    justifyContent: "center",
                  }}
                  className={type === "meeting" ? "active" : ""}
                  onClick={() => onOptionSelect("meeting")}
                >
                  Meeting
                </Button>
                <AnalyticsBtn
                  className={type === TYPE_LIST.STATS ? "active" : ""}
                  onClick={() => onOptionSelect(TYPE_LIST.STATS)}
                >
                  <Icon type={TYPE_LIST.STATS} style={{ color: "#fff" }} />
                </AnalyticsBtn>
                <Button
                  style={{
                    width: "100%",
                    height: "50px",
                    justifyContent: "center",
                  }}
                  className={type === TYPE_LIST.SENTENCE ? "active" : ""}
                  onClick={() => onOptionSelect(TYPE_LIST.SENTENCE, true)}
                >
                  Transcript
                </Button>
              </div>
            )}
          </div>
        </FlexContainer>
      </>
    </Container>
  );
};

export default MeetingOptionPanel;
