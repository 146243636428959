import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Input,
  InputGroup,
} from "reactstrap";
import styled from "styled-components";

import { APP_LAYOUT, COLORS, SPACING, TYPES_WITH_TOOLBAR, TYPE_LIST, TYPOGRAPHY } from "../../shared/constants";
import { JSONtoHTML } from "../../shared/utils/reformatScript";
import { Card } from "../../shared/ui/Cards";
// import { ContentCardContainer } from "../../shared/ui/Containers";
import { Icon, ICON_TYPES } from "../../shared/ui/Icon";
import { OptionButton as Button } from "shared/ui";


const IconIndicator = styled.div`
  width: 40px;
  margin-right: auto;
`;

const IconCircle = styled.div`
  border-radius: 100%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    color: ${({theme}) => theme.type === APP_LAYOUT.ZOOM ? theme.text.color : theme.colors.active }
  }
`;

const ContentCardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  // max-width: 750px;
  width: 100%;
  margin: 0 auto ${SPACING.SM}px;
`;

const Details = styled(Card)`
  flex:1;
  display:flex;
  // background-color:green;
  width: calc(100% - 40px);
  margin-top: 0;
  padding: ${SPACING.XS}px ${SPACING.SM}px;
`;

const getStyles = (type) => {
  let styles = {};
  switch (type) {
    case TYPE_LIST.DISCOVERY_QUESTIONS:
      styles = {
        card: { background: "#1B1B1B", marginTop: 5, paddingRight: 5 },
        title: { color: "#F39508" },
      };
      break;
    case TYPE_LIST.OBJECTIONS:
      styles = {
        card: { background: "#1B1B1B", marginTop: 5, paddingRight: 5 },
        title: { color: "#DE602B" },
      };
      break;
    case TYPE_LIST.SOCIALPROOFS:
      styles = {
        card: { background: "#1B1B1B", marginTop: 5, paddingRight: 5 },
        title: { color: "#8A5609" },
      };
      break;
    default:
      styles = {
        card: { background: "#242424", marginTop: 5, paddingRight: 5 },
        title: { color: "whitesmoke" },
      };
      break;
  }
  return styles;
};

const MeetingContentCard = ({
  id,
  type,
  title,
  content,
  onAnswerClicked,
  boardKey,
  boardItemContent,
  onDeleteBoardItem,
  isPastMeeting,
  onCardClick,
}) => {
  const { info } = useSelector((state) => state.users);
  const [otherOption, setOtherOption] = useState(false);
  const [otherOptionAnswer, setOtherOptionAnswer] = useState("");
  // const [selectedOptions, setSelectedOptions] = useState(content?.selectedOptions || {})

  // const styles = getStyles(type);
  let formattedContent = null;
  let contentText = ""
  let script = null

  switch (type) {
    case TYPE_LIST.TRIGGERS:
      if (content?.Versions) {
        const versionId = Object.keys(content.Versions)[0];
        script = JSONtoHTML(
          content?.Versions?.[versionId]?.content,
          { member: info }
        );
        formattedContent = <div dangerouslySetInnerHTML={{ __html: script }} />
      }
      break;
    case TYPE_LIST.DISCOVERY_QUESTIONS:
    case TYPE_LIST.OBJECTIONS:
    case TYPE_LIST.SOCIALPROOFS:
      if (TYPES_WITH_TOOLBAR.includes(type)) {
        script = JSONtoHTML(
          content?.content,
          { member: info }
        );
        contentText = <div dangerouslySetInnerHTML={{ __html: script }} />

      } else {
        contentText = content?.content
      }
      formattedContent = (
        <>
          <p style={{fontWeight: 600, marginBottom: 8}}>{content?.label}</p>
          <div>{contentText}</div>
        </>
      );
      break;
    case TYPE_LIST.FAQS:
      formattedContent = (
        <>
          <p style={{fontWeight: 600, marginBottom: 8}}>{content?.label}</p>
          <div>{content.answer}</div>
          {content?.tags?.length ? (
            <div style={{ marginTop: 10 }}>
              <b>Tags: </b>
              {content.tags.join(", ")}
            </div>
          ) : null}
        </>
      );
      break;
    case TYPE_LIST.BATTLESCARDS:

      script = JSONtoHTML(
        content?.content,
        { member: info }
      );
      contentText = <div dangerouslySetInnerHTML={{ __html: script }} />
      formattedContent = (
        <>
          <p style={{fontWeight: 600, marginBottom: 8}}>{content?.label}</p>
          <div>{contentText}</div>
          <div style={{ marginTop: 10 }}>
            {/* <div>
              {content.Strengths ? (
                <>
                  <span style={{ borderBottom: "1px solid crimson" }}>
                    Strengths:
                  </span>{" "}
                  {content.Strengths}
                </>
              ) : null}
            </div> */}
            <div>
              {content.Weaknesses ? (
                <>
                  <span style={{ borderBottom: "1px solid crimson" }}>
                    Counterpoints:
                  </span>{" "}
                  {content.Weaknesses}
                </>
              ) : null}
            </div>
          </div>
        </>
      );
      break;
    default:
      break;
  }

  // let prevResponseKey = Object.keys(boardItemContent).filter((val) =>
  //   val.startsWith("response_")
  // );


  // let answerIndex = null;
  // if (prevResponseKey) {
  // answerIndex = prevResponseKey.split("response_")[1];
  // answerIndex = answerIndex !== "input" ? parseInt(answerIndex) : answerIndex;
  // }


  return (
    <ContentCardContainer>
      <IconIndicator>
        <IconCircle>
          <Icon
            type={ICON_TYPES[type]}
            style={{ fontSize: TYPOGRAPHY.h3 + 2 }}
          />
        </IconCircle>
      </IconIndicator>
      <Details
        // style={styles.card}
        onClick={() => onCardClick && onCardClick(id)}
      >
        <div style={{
          display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between',
        }}>
          <div style={{ flexGrow: 1, paddingRight: 8 }}>
            {formattedContent}
            {content?.options && (
              <div
                style={{
                  display: "flex",
                  flexWrap: 'wrap',
                  // marginTop: SPACING.XS
                }}
              >
                {content.options.map((val, index) => {
                  return <Button
                    style={{
                      margin: `0 ${SPACING.XS}px ${SPACING.XS}px 0 `, marginLeft: 0,
                      backgroundColor: boardItemContent.selectedOptions && boardItemContent.selectedOptions[index] ? COLORS.azureRadiance : COLORS.eastBay
                    }}
                    key={id + "_" + index}
                    size="sm"
                    disabled={isPastMeeting}
                    onClick={() => {
                      onAnswerClicked(val, index, id, type, boardKey, otherOptionAnswer, {
                        ...boardItemContent?.selectedOptions,
                        [index]: boardItemContent.selectedOptions && boardItemContent.selectedOptions[index] ? false : true
                      });
                      // setSelectedOptions()
                    }}
                  >
                    {val}
                  </Button>
                })}
                <Button
                  style={{
                    margin: `0 ${SPACING.XS}px ${SPACING.XS}px 0`,
                    backgroundColor: boardItemContent.selectedOptions && boardItemContent.selectedOptions["other"] ? COLORS.azureRadiance : COLORS.eastBay

                  }}
                  size="sm"
                  color={"light"}
                  disabled={isPastMeeting}
                  onClick={() => {

                    // if (answerIndex !== "input") {
                    setOtherOption(!otherOption);
                    // } else {
                    if (!otherOption) {
                      onAnswerClicked(
                        "Other",
                        null,
                        id,
                        type,
                        boardKey,
                        otherOptionAnswer,
                        {
                          ...boardItemContent?.selectedOptions,
                          other: true
                        }
                      );
                    } else {
                      onAnswerClicked(
                        "Other",
                        null,
                        id,
                        type,
                        boardKey,
                        otherOptionAnswer,
                        {
                          ...boardItemContent?.selectedOptions,
                          other: false
                        }
                      );
                    }
                    // setOtherOptionAnswer("");
                    // }
                  }}
                >
                  {isPastMeeting ? boardItemContent.response_input : "Other"}
                </Button>
                {otherOption && (
                  <InputGroup>
                    <Input
                      type="text"
                      onChange={(e) => { setOtherOptionAnswer(e.target.value) }}
                      value={otherOptionAnswer}
                    />{" "}
                    {/* <Button
                      color="light"
                      size="sm"
                      onClick={() => {
                        onAnswerClicked(
                          "Other",
                          null,
                          id,
                          type,
                          boardKey,
                          otherOptionAnswer,
                          {...boardItemContent?.selectedOptions}
                        );
                      }}
                    >
                      Done
                    </Button> */}
                  </InputGroup>
                )}
              </div>
            )}
          </div>

          {type !== TYPE_LIST.TRIGGERS && !isPastMeeting && (
            <Icon type={ICON_TYPES.close}
              style={{ fontSize: TYPOGRAPHY.h3, cursor: 'pointer', top: 10, right: 10, position: 'absolute' }}
              onClick={() => onDeleteBoardItem(boardKey)} />
          )}
        </div>
      </Details>
    </ContentCardContainer>
  );
};

export default MeetingContentCard;
