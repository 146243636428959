import React from "react";
import { Route, Routes } from "react-router-dom";

import Dashboard from "../components/main/Dashboard";
import Login from "../components/auth/Login";
import PrivateRoute from "../components/route/PrivateRoute";
import Register from "../components/auth/Register";
import SocialRegister from "../components/auth/SocialRegister";
import MeetingLayout from "../components/call/MeetingLayout";
import History from "../components/main/History";

import MeetingPlayback from "../components/call/MeetingPlayback";
import AuthWrapper, { BackgroundContainer } from "../components/auth/AuthWrapper";
import Contact from "../components/contact/ContactView";
import HorizontalAnimationWrapper from "shared/ui/HorizontalAnimationWrapper";
import Saved from "../components/main/Saved";
import RecentWins from "../components/main/RecentWins";
import ForgotPassword from "../components/main/ForgotPassword";
import { APP_THEME , APP_LAYOUT } from "shared/constants";

const Index = () => (
  <Routes>
    <Route
      path="/"
      exact
      element={
        <AuthWrapper showHeader={false}>
          <HorizontalAnimationWrapper>
            <Login />
          </HorizontalAnimationWrapper>
        </AuthWrapper>
      }
    />
    <Route
      path="/register/:appName"
      element={
        <AuthWrapper showHeader={false}>
          <HorizontalAnimationWrapper>
            <Register />
          </HorizontalAnimationWrapper>
        </AuthWrapper>
      }
    />
    <Route
      path="/register/new/:uid"
      element={
        <AuthWrapper>
          <HorizontalAnimationWrapper>
            <SocialRegister />
          </HorizontalAnimationWrapper>
        </AuthWrapper>
      }
    />
    <Route
      path="/meeting/live/:interactionId"
      element={
        <PrivateRoute>
          <AuthWrapper>
            <HorizontalAnimationWrapper>
              <MeetingLayout />
            </HorizontalAnimationWrapper>
          </AuthWrapper>
        </PrivateRoute>
      }
    />
    <Route
      path="/prepare"
      element={
        <PrivateRoute>
          <AuthWrapper>
            <HorizontalAnimationWrapper>
              <MeetingLayout isPrep />
            </HorizontalAnimationWrapper>
          </AuthWrapper>
        </PrivateRoute>
      }
    />
     <Route
      path="/prepare/playBook/:playBook/meetingPlan/:meetingPlan"
      element={
        <PrivateRoute>
          <AuthWrapper>
            <HorizontalAnimationWrapper>
              <MeetingLayout isPrep />
            </HorizontalAnimationWrapper>
          </AuthWrapper>
        </PrivateRoute>
      }
    />
    <Route
      path="/prepare/:prepareId"
      element={
        <PrivateRoute>
          <AuthWrapper>
            <HorizontalAnimationWrapper>
              <MeetingLayout isPrep />
            </HorizontalAnimationWrapper>
          </AuthWrapper>
        </PrivateRoute>
      }
    />
    <Route
      path="/saved"
      element={
        <PrivateRoute>
          <AuthWrapper>
            <HorizontalAnimationWrapper>
              <Dashboard page="saved"/>
            </HorizontalAnimationWrapper>
          </AuthWrapper>
        </PrivateRoute>
      }
    />
    <Route
      path="/recent-wins"
      element={
        <PrivateRoute>
          <AuthWrapper>
            <HorizontalAnimationWrapper>
              <Dashboard page="recent-wins"/>
            </HorizontalAnimationWrapper>
          </AuthWrapper>
        </PrivateRoute>
      }
    />
    <Route
      path="/meeting/play/:accessCode"
      element={
        <BackgroundContainer theme={APP_THEME[APP_LAYOUT.UPMARKET]}>
          <HorizontalAnimationWrapper>
            <MeetingPlayback />
          </HorizontalAnimationWrapper>
        </BackgroundContainer>
      }
    />
    <Route
      path="/meeting/:interactionId"
      element={
        <PrivateRoute>
          <AuthWrapper>
            <HorizontalAnimationWrapper>
              <MeetingPlayback />
            </HorizontalAnimationWrapper>
          </AuthWrapper>
        </PrivateRoute>
      }
    />
    <Route
      path="/conversation/types/:reason"
      element={
        <PrivateRoute>
          <AuthWrapper>
            <HorizontalAnimationWrapper>
              <MeetingLayout isList />
            </HorizontalAnimationWrapper>
          </AuthWrapper>
        </PrivateRoute>
      }
    />
    <Route
      path="/home"
      element={
        <PrivateRoute>
          <AuthWrapper>
            <HorizontalAnimationWrapper>
              <Dashboard />
            </HorizontalAnimationWrapper>
          </AuthWrapper>
        </PrivateRoute>
      }
    />
    <Route
      path="/history"
      element={
        <PrivateRoute>
          <AuthWrapper>
            <HorizontalAnimationWrapper>
              <Dashboard page="history"/>
            </HorizontalAnimationWrapper>
          </AuthWrapper>
        </PrivateRoute>
      }
    />
    <Route
      path="/contact"
      element={
        <PrivateRoute>
          <HorizontalAnimationWrapper>
            <Contact />
          </HorizontalAnimationWrapper>
        </PrivateRoute>
      }
    />
    <Route
      path="/forgot-password"
      element={
        <AuthWrapper>
          <HorizontalAnimationWrapper>
            <ForgotPassword />
          </HorizontalAnimationWrapper>
        </AuthWrapper>
      }
    />
    <Route
      path="*"
      element={
        <PrivateRoute>
          <AuthWrapper>
            <HorizontalAnimationWrapper>
              <Dashboard />
            </HorizontalAnimationWrapper>
          </AuthWrapper>
        </PrivateRoute>
      }
    />
  </Routes>
);

export default Index;
