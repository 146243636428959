import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

import AddMeetingBotModal from "./modals/AddMeetingBotModal";
import styled from "styled-components";
import { Alert } from "reactstrap";

import {
  SPACING,
  HOUR_IN_SECONDS,
  TRIAL_TIME_LIMIT,
  ERROR_CODES,
  TYPOGRAPHY,
  COLORS,
  BORDER,
} from "shared/constants";
import { useAuth } from "../../contexts/AuthContext";
import { TinyText } from "shared/ui/Headers";
import { Icon } from "shared/ui";
import { ToolTipItem } from "../../shared/ui/ToolTips";
import { addMeetingBot, getUserScheduledMeetingsLimit, removeMeetingBot } from "../../redux/actions/meetings";
import { handleUserAutoSchedule } from "../../redux/actions/users";
import { ICON_TYPES } from "shared/ui/Icon";
import { colorGenerator, getColorValues } from "shared/utils/common";
import { useRef } from "react";
import { useInView } from 'react-intersection-observer';
const CONFERENCING_TYPES = {
  ZOOM: "Zoom Meeting",
  GOOGLE: "Google Meet",
};
const CheckBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 11px;
`;

const CheckBoxLabel = styled.label`
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 1px;
  &:checked + ${CheckBoxLabel} {
    background: #4fbe79;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

function isItBeforeToday(MomentDate) {
  return MomentDate.diff(moment(0, "HH")) < 0;
}
const MeetingLabel = styled.p`
  // width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
`;
const MeetingItem = styled.div`
  ${({ background, isPrimary, theme }) => `
    background-color: ${
      isPrimary ? "transparent" : background || "rgb(49, 205, 222,.55)"
    };
    ${isPrimary ? `border: 1px solid ${theme.colors.primary};` : ""}
    // border-bottom: 1px solid ${theme.border.color};
    color: ${theme?.text?.color};
    padding: ${SPACING.SM}px;
    border-radius: ${BORDER.radius_sm}px;
    margin-bottom: ${SPACING.XS}px;
    cursor: pointer;
  `}
`;

const ScheduleList = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const PreviewContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 5;
`;
const PreviewInfo = styled.div`
  margin-top: auto;
  height: 100%;
  width: 100%;
  background: #fff;
  .scroll-event-container {
    overflow: scroll;
    height: 90%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const GridTwoCols = styled.div`
  display: grid;
  grid-template-columns: 20px 2fr;
  grid-gap: ${SPACING.SM}px;
  .icon {
    font-size: 16px;
    margin: 0 auto;
  }
`;

const PreviewEvent = ({ previewEvent, setPreviewEvent, theme , calendars}) => {
  const { when } = previewEvent;
  const [conferencingUrl, setConferencingUrl] = useState(false);
  const getConferencingUrl = () => {
    let url = false;
    switch (previewEvent?.conferencing?.provider) {
      case CONFERENCING_TYPES.ZOOM:
        url = previewEvent?.conferencing?.details?.url;
        break;
      case CONFERENCING_TYPES.GOOGLE:
        url = previewEvent?.conferencing?.details?.url;
        break;
      default:
        url = false;
        break;
    }
    setConferencingUrl(url);
  };

  const eventDate = () => {
    let scheduledTime = false;
    if (when?.object === "date") {
      scheduledTime = moment(when.date).format("dddd, MMMM Do YYYY");
    } else if (when?.object === "timespan") {
      let start = moment.unix(when.start_time).format("MM/D/YYYY");
      let end = moment.unix(when.end_time).format("MM/D/YYYY");
      if (start === end) {
        scheduledTime = `${moment
          .unix(when.start_time)
          .format("dddd: MMMM Do YYYY, h:mm a")} - ${moment
          .unix(when.end_time)
          .format("h:mm a")}`;
      } else {
        scheduledTime = `${moment
          .unix(when.start_time)
          .format("MMMM Do YYYY, h:mm a")} - ${moment
          .unix(when.end_time)
          .format("MMMM Do YYYY, h:mm a")}`;
      }
    } else if (when?.object === "datespan") {
      let start = moment(when.start_date)
        .format("MMMM Do YYYY");
      let end = moment(when.end_date)
      scheduledTime  = start + " - " + end
      .format(" MMMM Do YYYY");
    } else if (when.object === "time") {
      scheduledTime = moment.unix(when.time).format("MMMM Do YYYY, h:mm a");
    }
    return scheduledTime;
  };

  useEffect(() => {
    getConferencingUrl();
    return () => {
      setConferencingUrl();
    };
  }, []);
  return (
    <PreviewContainer>
      <PreviewInfo>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          onClick={() => setPreviewEvent()}
        >
          <Icon
            type={ICON_TYPES.close}
            style={{
              fontSize: 22,
              width: 25,
              height: 25,
              cursor: "pointer",
            }}
          />
        </div>

        <h4>{previewEvent?.title}</h4>
        <TinyText>{eventDate()}</TinyText>
        <br />
        <br />
        <div className="scroll-event-container">
          {previewEvent?.calendarName && (
            <GridTwoCols>
              <Icon type={ICON_TYPES.calendar} />
              <p>{previewEvent?.calendarName}</p>{" "}
            </GridTwoCols>
          )}
          {previewEvent?.location && (
            <GridTwoCols>
              <Icon type={ICON_TYPES.location} />
              <p>{previewEvent?.location}</p>{" "}
            </GridTwoCols>
          )}
          {previewEvent?.participants && (
            <GridTwoCols>
              <Icon type={ICON_TYPES.users} />
              <div>
                {previewEvent?.participants.map((participant, i) => (
                  <div style={{ display: "flex", marginBottom: 8 }} key={i}>
                    <div style={{ width: 16, marginRight: 8 }}>
                      {participant.status !== "noreply" && (
                        <Icon
                          style={{
                            color:
                              participant.status == "yes"
                                ? theme.colors.success
                                : theme.colors.danger,
                          }}
                          type={
                            ICON_TYPES[
                              participant.status === "yes" ? "check" : "delete"
                            ]
                          }
                        />
                      )}
                    </div>
                    <span>{participant.name}</span>
                  </div>
                ))}
              </div>{" "}
            </GridTwoCols>
          )}
          {conferencingUrl && (
            <GridTwoCols>
              <Icon type={ICON_TYPES.video} />
              <p>
                <a href={conferencingUrl} target="_blank" rel="noreferrer">
                  {conferencingUrl}
                </a>
              </p>
            </GridTwoCols>
          )}
          {previewEvent?.description && (
            <GridTwoCols>
              <Icon type={ICON_TYPES.note} />{" "}
              <p style={{ wordBreak: "break-word" }}>
                {previewEvent?.description.split("\n").map((sentence, j) => (
                  <span key={j}>
                    {sentence} <br />
                  </span>
                ))}
              </p>{" "}
            </GridTwoCols>
          )}
        </div>
      </PreviewInfo>
    </PreviewContainer>
  );
};

const MeetingListItem = ({
  scheduledBots,
  addBot,
  isLast,
  theme,
  handleCancelBot,
  calendarName,
  background,
  onPreview,
  event,
}) => {
  let { title, when, id, participants, isPrimary, calendar_id, dayCount = 0 } = event;
  let scheduledTime = null;
  let scheduledEndTime = null;

  let isBotScheduled =
    scheduledBots &&
    Object.keys(scheduledBots).find(
      (key) => scheduledBots[key]?.nylasEventId === id
    );
  const [isHover, setHover] = useState();
  if (when?.object === "date") {
    scheduledTime = when.date;
  } else if (when?.object === "timespan") {
    scheduledTime = moment.unix(when.start_time).format("h:mm A");
    scheduledEndTime = moment.unix(when.end_time).format("h:mm A");
  } else if (when?.object === "datespan") {
    scheduledTime = when.start_date;
  } else if (when?.object === "time") {
    scheduledTime = moment.unix(when.time).format("h:mm A");
  }

  const isConferencing = () => {
    return (
      event?.conferencing?.provider === CONFERENCING_TYPES.ZOOM ||
      event?.conferencing?.provider === CONFERENCING_TYPES.GOOGLE
    );
  };
  return (
    <MeetingItem isLast={isLast} background={background} isPrimary={isPrimary}>
      <div
        style={{
          display: "flex",
        }}
        onClick={() => onPreview(event)}
      >
        <div style={{flexGrow: 1, maxWidth: 320}}>
          <ToolTipItem itemId={"meeting_title_" + id + "_" + calendar_id + +"_"+ dayCount} label={title}>
            <MeetingLabel style={{ marginBottom: 0 }}>{title}</MeetingLabel>
          </ToolTipItem>
          {(event.when.object === "timespan" ||
            event.when.object === "time") && (
            <TinyText style={{ color: COLORS.softBlack }}>
              {scheduledTime} {scheduledEndTime && "-" + scheduledEndTime}
            </TinyText>
          )}
        </div>
        {participants?.length && (
          <div className="flex" >
            <Icon
              style={{ marginRight: 8, color: theme.text.color }}
              type={ICON_TYPES.users}
            />
            <TinyText style={{ color: theme.text.color }}>
              {participants?.length}
            </TinyText>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {isBotScheduled && (
            <TinyText
              link
              color={isHover ? theme.colors.danger : theme.text.color}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              onClick={() => handleCancelBot(isHover, id)}
              style={{marginBottom: SPACING.XS,}}
            >
              {isHover ? (
                <>
                  {" "}
                  <Icon
                    type="delete"
                    style={{
                      marginRight: SPACING.XXS,
                      color: theme.colors.danger,
                      fontSize: 16,
                    }}
                  />{" "}
                  Cancel notetaker
                </>
              ) : (
                <>
                  {" "}
                  <Icon
                    type="check"
                    style={{
                      marginRight: SPACING.XXS,
                      color: theme.colors.success,
                      fontSize: 16,
                    }}
                  />{" "}
                  Scheduled
                </>
              )}
            </TinyText>
          )}
          {!isBotScheduled && isPrimary && isConferencing() && (
            <TinyText link color={theme.text.color} onClick={() => addBot(id)}
              style={{marginBottom: SPACING.XS,}}
            >
              <Icon
                type="plusCircle"
                style={{
                  marginRight: SPACING.XXS,
                  color: theme.colors.primary,
                  fontSize: 16,
                }}
              />{" "}
              Send notetaker
            </TinyText>
          )}
        </div>
      </div>
    </MeetingItem>
  );
};

const ScheduledMeetings = () => {
  const { scheduledMeetings, scheduledBots, calendars } = useSelector(
    (state) => state.meetings
  );

  const { info, isUpdatingAutoJoin, subscription } = useSelector(
    (state) => state.users
  );
  const [groupedScheduledMeetings, setGroupedScheduledMeetings] = useState({});
  const [calendarColors, setCalendarColors] = useState([]);

  const [showJoinBot, setShowJoinBot] = useState(false);
  const [automaticScheduling, setAutomaticScheduling] = useState(false);
  const [previewEvent, setPreviewEvent] = useState(null);
  const dispatch = useDispatch();
  const { theme } = useAuth();
  const { uid, companyId, CalendarEvents } = info || {};
  const [limit, setLimit] = useState(30)
  const [prevCalendarEventCount, setPrevCalendarCount] = useState(0)

  const listRef = useRef()

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const handleJoinMeeting = async (eventId) => {
    try {
      await addMeetingBot(uid, companyId, eventId);
      toast("Notetaker added to meeting", {
        type: "success",
        hideProgressBar: true,
      });
    } catch (error) {
      toast(ERROR_CODES.TRIAL_RECORD_LIMIT.message, {
        type: "error",
        hideProgressBar: true,
      });
    }
  };

  const handleCancelBot = async (isRemove, eventId) => {
    if (isRemove) {
      try {
        await removeMeetingBot(uid, companyId, eventId);
        toast("Notetaker removed from meeting", {
          type: "info",
          hideProgressBar: true,
        });
      } catch (error) {
        toast("Error removing Notetaker from meeting", {
          type: "error",
          hideProgressBar: true,
        });
      }
    }
  };

  const handleAutoJoin = (e) => {
    setAutomaticScheduling(e.target.checked);
    toast("Updating auto join setting", {
      type: "info",
      hideProgressBar: true,
    });
    dispatch(handleUserAutoSchedule(uid, companyId, e.target.checked));
  };

  const onJoinMeeting = () => {
    setShowJoinBot(true);
  };

  const onLoadMore = () => {
    setLimit(limit + 20);
    setPrevCalendarCount(Object.keys(scheduledMeetings).length)
    dispatch(getUserScheduledMeetingsLimit(uid, companyId, limit + 20))
  }

  useEffect(() => {
    setAutomaticScheduling(CalendarEvents?.automaticScheduling || false);
  }, [CalendarEvents]);

  useEffect(() => {
    if (scheduledMeetings) {
      let groupedMeetings = {};
      Object.keys(scheduledMeetings)
        .map((key) => {
          let meeting = scheduledMeetings[key];
          meeting.calendarName = meeting?.calendar_id && calendars?.[meeting.calendar_id]?.name;
          if(meeting?.calendar_id && calendars?.[meeting?.calendar_id]?.isPrimary){
            meeting.isPrimary = true
          }
        
          const { when } = meeting;
          let scheduledTime = null;
          if (when?.object === "date") {
            scheduledTime = moment(when.date);
          } else if (when?.object === "timespan") {
            scheduledTime = moment.unix(when.start_time);
          } else if (when?.object === "datespan") {
            var duration = moment.duration(moment(when.end_date).diff(moment(when.start_date)));
            var days = Math.ceil(duration.asDays()) ;
            for(let i = 0; i < days; i++){
              const formattedDate = moment(when.start_date).add(i, 'days').format("MMMM Do YYYY");
              const formattedTime = moment(when.start_date).add(i, 'days')
                .format("YYYY-MM-DD")
                .replaceAll("-", "_");
              if (
                groupedMeetings[formattedTime] &&
                groupedMeetings[formattedTime]?.events?.length
              ) {
                groupedMeetings[formattedTime].events.push({...meeting, title: meeting.title + `: Day ${i +1}/${days}`, dayCount: i+1});
              } else {
                groupedMeetings[formattedTime] = {
                  label: formattedDate,
                  events: [{...meeting, title: meeting.title + `: Day ${i +1}/${days}`, dayCount: i+1}],
                };
              }
            }

            scheduledTime = moment(when.start_date);
            return
          } else if (when?.object === "time") {
            console.log("time", when);
          }

          // if(isItBeforeToday(scheduledTime)) {
          //     return;
          // }
          const formattedDate = scheduledTime.format("MMMM Do YYYY");
          const formattedTime = scheduledTime
            .format("YYYY-MM-DD")
            .replaceAll("-", "_");
          if (
            groupedMeetings[formattedTime] &&
            groupedMeetings[formattedTime]?.events?.length
          ) {
            groupedMeetings[formattedTime].events.push(meeting);
          } else {
            groupedMeetings[formattedTime] = {
              label: formattedDate,
              events: [meeting],
            };
          }
        });
        let count = 0
      Object.keys(groupedMeetings).map((meetingDate) => {
        let sorted = groupedMeetings[meetingDate]?.events.sort(
          (a, b) => a.start_time - b.start_time
        );
        count = count + sorted.length
        groupedMeetings[meetingDate].events = sorted || [];
      });
      setGroupedScheduledMeetings(groupedMeetings);
    }
  }, [scheduledMeetings]);

  useEffect(() => {
    if (calendars) {
      let colors = getColorValues(
        [71, 49, 222],
        [49, 205, 222],
        Object.keys(calendars).length || 10,
        0.25
      );
      let calendarColorsTemp = {};
      Object.keys(calendars).map((id, i) => {
        calendarColorsTemp[id] = colors[i];
      });
      setCalendarColors(calendarColorsTemp);
    }
  }, [calendars]);
  
  useEffect(() => {
    if(inView){
      onLoadMore()
    }
  }, [inView])

  return (
    <Container>
      <AddMeetingBotModal
        isOpen={showJoinBot}
        toggle={() => setShowJoinBot(!showJoinBot)}
      />

      <div style={{ marginBottom: SPACING.SM }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ flexGrow: 1 }}>
            <h3 style={{ fontWeight: "bold" }}>Upcoming Meetings</h3>
          </span>
          <CheckBoxWrapper>
            <span>Auto Join</span>
            <CheckBox
              disabled={isUpdatingAutoJoin}
              id="checkbox"
              type="checkbox"
              checked={automaticScheduling}
              onChange={handleAutoJoin}
            />
            <CheckBoxLabel htmlFor="checkbox" />
          </CheckBoxWrapper>
        </div>
        <TinyText link onClick={onJoinMeeting}>
          Join a meeting
        </TinyText>
      </div>
      {(subscription?.status === "free" || subscription?.status === "trial") &&
        info?.meetingSeconds >= TRIAL_TIME_LIMIT && (
          <Alert color="danger"> Meeting Recording Limit Exceeded</Alert>
        )}

      <ScheduleList
        ref={listRef}
      >
        {groupedScheduledMeetings &&
          Object.keys(groupedScheduledMeetings)
            .sort()
            .map((key, i) => {
              const meetingList = groupedScheduledMeetings[key]?.events || [];
              return (
                <div key={key + "_" + i}>
                  <h4 style={{ fontWeight: "700", marginTop: SPACING.LG }}>
                    {groupedScheduledMeetings[key]?.label}
                  </h4>
                  <div>
                    {meetingList.map((val, index) => (
                      <MeetingListItem
                        background={calendarColors[val?.calendar_id]}
                        theme={theme}
                        isLast={Object.keys(meetingList).length - 1 === index}
                        addBot={handleJoinMeeting}
                        scheduledBots={scheduledBots}
                        handleCancelBot={handleCancelBot}
                        event={val}
                        key={key + "_" + index + "_" + i}
                        onPreview={(event) => setPreviewEvent(event)}
                      />
                    ))}
                  </div>
                </div>
              );
            })}
            {Object.keys(scheduledMeetings).length !== prevCalendarEventCount && <p ref={ref}>Load more</p>}
      </ScheduleList>

      {previewEvent && (
        <PreviewEvent
          previewEvent={previewEvent}
          setPreviewEvent={setPreviewEvent}
          theme={theme}
          calendars={calendars}
        />
      )}
    </Container>
  );
};

export default ScheduledMeetings;
