import { PopOver } from "shared/ui/Modals";
import { Icon, ICON_TYPES } from "shared/ui/Icon";
import { SPACING, COLORS, TYPOGRAPHY } from "shared/constants";
import styled from "styled-components";
import ShareMeetingModal from "../modals/ShareMeetingModal";
import OutcomeModal from "../modals/OutcomeModal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "../../../shared/ui/Modals";
import SalesforceForm from "../SalesforceForm";
import { CRM_TYPES } from "shared/constants/recordTypes";
import { CONNECTED_SERVICES } from "shared/constants";
import { toast } from "react-toastify";
import { deleteMeetingPrepare } from "../../../redux/actions/meetings";
import { useNavigate } from "react-router-dom";

const MenuItem = styled.div`
  padding: ${SPACING.XXS}px ${SPACING.SM}px;
  margin: 0 -${SPACING.SM}px;
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.colors.active || COLORS.azureRadiance };
  }
`;

const ACTIONS = [
  { id: 1, label: "Add Outcome" },
  { id: 2, label: "Salesforce Sync" },
  // { id: 3, label: "Send Email" },
  // { id: 4, label: "Create contact" },
  // { id: 5, label: "Add to grid" },
  { id: 6, label: "Share meeting" },
  { id: 7, isPrep: true, label: "Delete" },
];

const ActionsMenu = (props) => {
  const {
    reloadInteraction,
    companyId,
    interactionId,
    interaction,
    uid,
    isPrep,
    handleOutcomeToggle,
    outcomeToggle = false,
    refresh=()=>{},
    onOutcomeLoading
  }=props;
  const { claims , salesforce} = useSelector((state) => state.member);
  let navigate = useNavigate();
  const [actions, setActions] = useState(ACTIONS?.filter(action => action.id === 2 ? salesforce?.user_id : true));
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [isOutcomeOpen, setIsOutcomeOpen] = useState(outcomeToggle);
  const [isSalesforceView, setIsSalesforceView] = useState(false);
  useEffect(() => {
    let { connectedServices, crm } = claims || {};
    connectedServices = connectedServices?.length ? connectedServices : [];
    let updatedActions = Array.from(actions);
    if (
      !(
        (crm === CRM_TYPES.SALESFORCE || crm === CRM_TYPES.CORE) &&
        !connectedServices?.includes(CONNECTED_SERVICES.SALESFORCE)
      )
    ) {
      updatedActions = updatedActions.filter((action) => {
        if( action.id === 2 ){
          console.log(crm === CRM_TYPES.SALESFORCE , salesforce?.user_id , connectedServices?.includes(CONNECTED_SERVICES.SALESFORCE))
          if(crm === CRM_TYPES.SALESFORCE && salesforce?.user_id && connectedServices?.includes(CONNECTED_SERVICES.SALESFORCE)){
            return true
          }else{
            return false
          }
        }else{
          return true
        }
      });
      setActions(updatedActions);
    }
  }, [claims]);

  const handleSalesforce = () => {
    setIsSalesforceView(!isSalesforceView);
  };

  const handleClick = async (action) => {
    switch (action) {
      case "Add Outcome":
        setIsOutcomeOpen(true);
        break;
      case "Salesforce Sync":
        await handleSalesforce();
        break;
      case "Send Email":
        break;
      case "Create contact":
        break;
      case "Add to grid":
        break;
      case "Share meeting":
        setIsShareOpen(true);
        break;
      case "Delete":
        try {
          await deleteMeetingPrepare(
            claims?.companyId,
            claims?.user_id,
            interactionId
          );
          toast("Template deleted", {
            hideProgressBar: true,
          });
          navigate("/saved");
        } catch (e) {
          toast("Template deleted failed, try again", {
            hideProgressBar: true,
          });
        }
        break;
      default:
        break;
    }
  };

  const onOutcomeToggle = () => {
    setIsOutcomeOpen(!isOutcomeOpen);
    handleOutcomeToggle();
  };

  useEffect(() => {
    if (outcomeToggle) {
      setIsOutcomeOpen(true);
    }
  }, [outcomeToggle]);
  return (
    <>
      <ShareMeetingModal
        isOpen={isShareOpen}
        toggle={() => setIsShareOpen(!isShareOpen)}
        interactionId={interactionId}
        meetingLabel={interaction?.topic}
      />
      <OutcomeModal
        isOpen={isOutcomeOpen}
        companyId={companyId}
        toggle={onOutcomeToggle}
        interactionId={interactionId}
        interaction={interaction}
        onSave={refresh}
        onOutcomeLoading={onOutcomeLoading}
      />
      <div
        id="meeting_actions"
        style={{
          padding: `0 ${SPACING.XS}px`,
          cursor: "pointer",
          marginLeft: SPACING.SM,
        }}
      >
        <Icon type={ICON_TYPES.ellipsis} style={{ cursor: "pointer" }} />
      </div>
      <PopOver placement="bottom" target="meeting_actions" trigger="legacy">
        {actions
          .filter((action) => isPrep === action.isPrep)
          .map((action, i) => {
            if(action.label === "Delete" && !interactionId){
            }else{
              return (
                <MenuItem key={"action_menu_"+ i} onClick={() => handleClick(action.label)}>
                  {action.label}
                </MenuItem>
              );
            }
          })}
      </PopOver>
      {isSalesforceView && (
        <Modal
          isOpen={isSalesforceView}
          toggle={() => setIsSalesforceView(false)}
        >
          <Icon
            className="modal_header-close"
            type={ICON_TYPES.close}
            style={{
              fontSize: TYPOGRAPHY.h3,
              position: "absolute",
              top: 28,
              right: 20,
            }}
            onClick={() => setIsSalesforceView(false)}
          />
          <ModalBody>
            <SalesforceForm
              reloadInteraction={reloadInteraction}
              uid={uid}
              companyId={companyId}
              interactionId={interactionId}
              interaction={interaction}
              toggle={() => setIsSalesforceView(false)}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default ActionsMenu;
