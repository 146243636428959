import axios from "axios";
import { trackPromise } from "react-promise-tracker";

import { realtimeDb } from "../../firebase/firebase";
import { globalStore } from "../store";
import diff from 'deep-diff';

let contentListenerRef = null;
let contentListenerCallback = null;

let activityMeetingInteractionListener = null;
let activityMeetingRef = null;

let activityCallInteractionListener = null;
let activityCallRef = null;

export const getUserContent = (uid, companyId) => {
  return (dispatch) => {
    contentListenerRef = realtimeDb
      .ref(`Content/${companyId}`);
    contentListenerCallback = contentListenerRef.on("value", (snapshot) => {
        const state = globalStore.getState();
        const existingContent = state?.content?.company;
        const content = snapshot.val();
        if(existingContent && Object.keys(existingContent).length) {
          const difference = diff.diff(existingContent, content);
          if(difference?.length && difference.filter(val => val.kind === "N").length) {
            const filteredDifference = difference.filter(val => val.kind === "N");
            const contentObj = {};
            for (const val of filteredDifference) {
              const [type, key] = val.path;
              contentObj[type] = {
                ...contentObj[type],
                [key]: content[type][key]
              }
            }

            if(Object.keys(contentObj).length) {
              dispatch({
                type: "COMPANY_CONTENT_NEW",
                data: contentObj,
              });
            }
          }
        }

        dispatch({
          type: "COMPANY_CONTENT",
          data: content,
        });
        if (content?.CallFlows) {
          const findPlaybook = Object.keys(content?.CallFlows).find(id =>content?.CallFlows[id]?.active || !content?.CallFlows[id]?.deleted )
          const zoomCallFlow = findPlaybook?.[0] ? {...content?.CallFlows[findPlaybook], id: findPlaybook} :  {} 
          
          dispatch({
            type: "ZOOM_CONTENT",
            data: zoomCallFlow,
          });
        }
      })
    // onValue(ref(realtimeDb, `Content/${companyId}`), (snapshot) => {
    //   const content = snapshot.val();
    //   dispatch({
    //     type: "COMPANY_CONTENT",
    //     data: content,
    //   });
    //   if (content?.CallFlows) {
    //     let zoomCallFlow = {};
    //     for (const key of Object.keys(content.CallFlows)) {
    //       if (
    //         content.CallFlows[key]?.active &&
    //         content.CallFlows[key]?.label === "Zoom"
    //       ) {
    //         zoomCallFlow = content.CallFlows[key];
    //         dispatch({
    //           type: "ZOOM_CONTENT",
    //           data: zoomCallFlow,
    //         });
    //         break;
    //       }
    //     }
    //   }
    // });
  };
};

export const hideTeamFeedType = (type) => {
  return (dispatch)=>{
    dispatch({
      type: "HIDE_TEAM_FEED_TYPE",
      data: type,
    });
  }
}

export const deleteContentListeners = () => {
  if (contentListenerRef && contentListenerCallback) {
    contentListenerRef.off('value', contentListenerCallback);
  }
  // if (activityMeetingInteractionListener && activityMeetingRef) {
  //   activityMeetingRef.off("value", activityMeetingInteractionListener);
  // }

  // if (activityCallInteractionListener && activityCallRef) {
  //   activityCallRef.off("value", activityCallInteractionListener);
  // }
}

export function addRecord(companyId, recordType, contactInfo) {
  // let companyId = member.claims.companyId;
  return new Promise(async (resolve) => {
    let values = {
      companyId,
      recordType,
      recordInfo: contactInfo,
    };
    // const contactId =  await axios.post(`http://localhost:9080/add-record`, values);
    const contactId = await axios.post(
      `https://upmarket-records-dot-accountheroes.appspot.com/add-record`,
      values
    );
    resolve(contactId);
  });
}

export function updateRecord(companyId, recordType, recordId, data) {
  // let companyId = member.claims.companyId;
  return new Promise(async (resolve) => {
    let values = {
      companyId,
      recordType,
      recordId,
      recordInfo: data,
    };
    const contactId = await axios.post(
      `https://upmarket-records-dot-accountheroes.appspot.com/update-record`,
      values
    );
    resolve(contactId);
  });
}

export function getRecords(companyId, recordType, searchText) {
  // let companyId = member.claims.companyId;
  console.log("getRecords", companyId);
  return new Promise(async (resolve) => {
    let values = {
      companyId,
      recordType,
      searchText,
    };
    const records = await axios.post(
      `https://upmarket-records-dot-accountheroes.appspot.com/get-records`,
      values
    );
    resolve(records);
  });
}

export function getRecord(companyId, type, id) {
  return trackPromise(
    new Promise(async (resolve, reject) => {
      if (companyId) {
        try {
          realtimeDb.ref(`Records/${companyId}/${type}/${id}`).orderByChild("id").equalTo(id).once("value").then(snapshot => {
            console.log("spanshot val", snapshot.val());
            let record = snapshot.val();
            if (record) {
              resolve(record);
            }else{
              resolve(false)
            }
          });
          // const dbRef = ref(realtimeDb, `Records/${companyId}/${type}/${id}`);
          // const queryConstraints = [orderByChild("id"), equalTo(id)];
          // onValue(
          //   ref(realtimeDb, `Records/${companyId}/${type}/${id}`),
          //   (snapshot) => {
          //     console.log("spanshot val", snapshot.val());
          //     let record = snapshot.val();
          //     if (record) {
          //       resolve(record);
          //     }
          //   }
          // );
        } catch (e) {
          console.log("Record Not Found");
          return false;
        }
      }
    })
  );
}

export const getCoreFields = (companyId, recordType) => {
  return new Promise((resolve, reject) => {
    realtimeDb.ref(`Companies/${companyId}/core/fields/${recordType}`).once("value").then((snapshot) => {
      resolve(snapshot.val());
    })
    // onValue(
    //   ref(realtimeDb, `Companies/${companyId}/core/fields/${recordType}`),
    //   (snapshot) => {
    //     resolve(snapshot.val());
    //   }
    // );
  });
};

export const getContactLayout = (companyId, recordType) => {
  return new Promise((resolve, reject) => {
    realtimeDb.ref(`Companies/${companyId}/core/layouts/contactCard/sections/core/fields`).once("value").then((snapshot) => {
      resolve(snapshot.val());
    })
    // onValue(
    //   ref(realtimeDb, `Companies/${companyId}/core/layouts/contactCard/sections/core/fields`),
    //   (snapshot) => {
    //     resolve(snapshot.val());
    //   }
    // );
  });
};

export const getUserRoles = (uid, companyId) => {
  return (dispatch) => {
    realtimeDb.ref(`Admin/${companyId}/Org/roles`).once("value").then((snapshot) => {
      const content = snapshot.val();
      console.log("HERE THE DATA", content);
      dispatch({
          type: "COMPANY_ROLES",
          data: content
      });
    })
      // onValue(ref(realtimeDb, `Admin/${companyId}/Org/roles`), (snapshot) => {
      //     const content = snapshot.val();
      //     console.log("HERE THE DATA", content);
      //     dispatch({
      //         type: "COMPANY_ROLES",
      //         data: content
      //     });
          
      // });
  }
}

export const getCompanyActivities = (companyId) => {
  return (dispatch) => {
    activityMeetingRef = realtimeDb.ref(`Interactions/${companyId}/meetings`);
    activityCallRef = realtimeDb.ref(`Interactions/${companyId}/calls`);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    activityMeetingInteractionListener = activityMeetingRef
      .orderByChild("end_time")
      .limitToLast(10)
      // .startAfter(today.toISOString())
      .once("value").then((snapshot) => {
        let meeting = snapshot.val();
        dispatch({
          type: "RECENT_INTERACTIONS",
          data: { meetings: { ...meeting } },
        });
      });
    activityCallInteractionListener = activityCallRef
      .orderByChild("dateCompleted")
      // .startAfter(today.toISOString())
      .limitToLast(15)
      .once("value").then((snapshot) => { 
        let call = snapshot.val();
        dispatch({
          type: "RECENT_INTERACTIONS",
          data: { calls: { ...call } },
        });
      });
  };
};
