export const isDevRunningEnvironment = () => {
  let dev = false;
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1" ||
    window.location.hostname === "0.0.0.0"
  ) {
    dev = true;
  }
  return dev;
};

export function colorGenerator(color1, color2, weight) {
  var p = weight;
  var w = p * 2 - 1;
  var w1 = (w / 1 + 1) / 2;
  var w2 = 1 - w1;
  var rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2),
  ];
  return rgb;
}


export function getColorValues(color_start, color_end, numOfItems, opacity = 1){
  let colors = [];
  for(let i = 0 ; i < numOfItems; i++ ){
   let [r,g,b] = colorGenerator(color_start, color_end,( i / numOfItems) || 0)
   colors.push(`rgba(${r}, ${g}, ${b}, ${opacity})`)
  }
  return colors
}