import styled from "styled-components";
import { APP_LAYOUT, COLORS, SCREEN_SIZES, SPACING } from "../constants";

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const FlexContainer = styled.div`
  display: flex;
  ${(props) =>
    props.isCenter
      ? `
        justify-content: center;
        align-items: center;
      `
      : `
    justify-content: ${props.justifyContent || "flex-start"};
    align-items: ${props.alignItems || "flex-start"};
  `}
`;

export const BodyContainer = styled.div`
  display:flex;
  flex:1
`;
export const MeetingBodyContainer = styled.div`
  display: block;
  ${props => props.isFull ? "width: 100%" :  
    `@media all and (min-width: ${SCREEN_SIZES.md}px) {
      display: grid;
      grid-template-columns: 1fr 45%;
      grid-column-gap: ${SPACING.SM}px;
    }`
  }
`;

export const ContentCardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  // max-width: 750px;
  width: 100%;
  margin: 0 auto ${SPACING.XS}px;
`;

export const ScrollContainer = styled.div`
  overflow: scroll;
  min-height: ${(props) => props.minHeight ? (typeof(props.minHeight) == 'number' ? props.minHeight +"px" : props.minHeight) :  "100%"};
  height: ${(props) => props.height ? (typeof(props.height) == 'number' ? props.height +"px" : props.height) :  "100%"};
  &::-webkit-scrollbar {
    ${({hideScrollbar}) => hideScrollbar ? "display:none;": ""}
    width: 6px;
    height: 6px;
    border-radius: 10px;
    padding-left: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.type === APP_LAYOUT.ZOOM ? COLORS.greyDark : theme.colors.grey1};
    border-radius: 10px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) => 
    props.col ? ("1fr ").repeat(props.col) : "1fr"
  };
  grid-template-rows: ${(props) => 
    props.row ? ("1fr ").repeat(props.row) : "1fr"
  };
  grid-gap: ${(props) => 
    props.gap || SPACING.XS
  }px;
`
